module.exports.MaxNoOfRealTimeColums = 60;
module.exports.DateFormat = 'YYYY-MM-DD';
module.exports.DateAndTimeFormat = 'YYYY-MM-DD HH:mm:ss';
module.exports.TimeFormat = 'HH:mm:ss';
module.exports.TimeFormatInHoursAndMinutes = 'HH:mm';
module.exports.pcsReportTimeFormat = 'DD-MMM-YYYY HH:mm:ss';
module.exports.parameterDataTypes = ['STRING', 'INTEGER', 'FLOAT'];
module.exports.DateTimeFormat = 'DD/MM/YYYY hh:mm:ss A'; //12 hrs format
// ref url https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
module.exports.LocalDateFormat = 'll'; // Format will chnage according to locale
module.exports.LocalDateTimeFormat = 'lll'; // Format will chnage according to locale

module.exports.pcsReportResults = {
  COMPLETED: 'COMPLETED',
  COMPETED_WITH_ALARM: 'COMPETED WITH ALARM',
  RUNNING: 'RUNNING',
  ABORTED: 'ABORTED'
};

module.exports.defaultNumberOfActionSequences = 20;

module.exports.recipeFormats = {
  CLASSIC: 'CLASSIC',
  SFC: 'SFC'
};

//Pagination constants
module.exports.showPaginationBottom = true;
module.exports.pageSize = 15;
