import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import { navigationConstants } from './faq_constant';

import I18nMessage from '../../components/i18n/I18nMessage';
import config from '../../utils/config';
import Switch from 'react-switch';

import moment from 'moment';
import _ from 'lodash';

export class ListViewColumns extends PureComponent {
  constructor(roles, actionColumnPermissions, changeStatus, toggleInfo) {
    super();
    this.roles = roles;
    this.changeStatus = changeStatus;
    this.actionColumnPermissions = actionColumnPermissions;
    this.toggleInfo = toggleInfo;
  }

  getColumns = () => {
    return [
      {
        Header: <I18nMessage id="Question" />,
        accessor: 'question'
      },
      {
        Header: <I18nMessage id="Answer" />,
        accessor: 'answer'
      },
      {
        Header: <I18nMessage id="Title" />,
        accessor: 'title'
      },
      {
        Header: <I18nMessage id="Type" />,
        accessor: 'type'
      },
      {
        Header: <I18nMessage id="Status" />,
        accessor: 'active',
        Cell: ({ original }) => {
          return (
            <Switch
              onChange={() => {
                return this.changeStatus(original);
              }}
              checked={original.active}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={20}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={15}
              width={40}
            />
          );
        }
      },
      {
        Header: <I18nMessage id="Actions" />,
        accessor: '_id',
        Cell: props => (
          <span>
            <Link to={`${navigationConstants.HELP_VIEW_PATH}${props.value}`}>
              <i
                className="icon-eye icons font-1xl px-2 cp"
                id={'Tooltip-view-' + props.value}
              />
            </Link>
            <UncontrolledTooltip
              placement="top"
              target={'Tooltip-view-' + props.value}
            >
              <I18nMessage id="View Details" />
            </UncontrolledTooltip>
            <span>
              <Link to={`${navigationConstants.HELP_EDIT_PATH}${props.value}`}>
                <i
                  className="icon-pencil icons font-1xl px-2 cp"
                  id={'Tooltip-edit-' + props.value}
                />
              </Link>
              <UncontrolledTooltip
                placement="top"
                target={'Tooltip-edit-' + props.value}
              >
                <I18nMessage id="Edit" />
              </UncontrolledTooltip>
            </span>
            <span>
              <a
                href="javascript:void(0);"
                onClick={() => {
                  this.toggleInfo(props.value);
                }}
              >
                <i
                  className="icon-trash icons font-1xl px-2 cp"
                  id={'Tooltip-delete-' + props.value}
                />
              </a>
              <UncontrolledTooltip
                placement="top"
                target={'Tooltip-delete-' + props.value}
              >
                <I18nMessage id="Delete" />
              </UncontrolledTooltip>
            </span>
          </span>
        ),
        filterable: false,
        sortable: false
      }
    ];
  };
}
