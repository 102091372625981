import React from 'react';
import Faq from '../../views/Faq';
import I18nMessage from '../i18n/I18nMessage';
import Application from './Application';

// FAQ
const ROLES = {
  SUPERADMIN: ['_SUPER'],
  SUPERADMIN_ADMIN: ['_SUPER', 'ADMIN'],
  ALL_ROLES: ['_SUPER', 'ADMIN', 'PRIVILEGE']
};

const Help = React.lazy(() => import('../../views/Faq'));

const AddNewHelp = React.lazy(() => import('../../views/Faq/AddFaq'));

const ViewHelp = React.lazy(() => import('../../views/Faq/ViewFaq'));

const EditHelp = React.lazy(() => import('../../views/Faq/EditFaq'));

// Dashboard
const Dashboard = React.lazy(() => import('../../views/Dashboard/Dashboard'));

// Lines
const Line = React.lazy(() => import('../../views/Configurator/Lines/index'));

const AddNewLine = React.lazy(() =>
  import('../../views/Configurator/Lines/AddNewLine')
);

const EditLine = React.lazy(() =>
  import('../../views/Configurator/Lines/EditLine')
);

const ViewLine = React.lazy(() =>
  import('../../views/Configurator/Lines/ViewLine')
);

// Shift
const Shift = React.lazy(() => import('../../views/Configurator/Shifts/index'));

const AddShift = React.lazy(() =>
  import('../../views/Configurator/Shifts/AddShift')
);

const EditShift = React.lazy(() =>
  import('../../views/Configurator/Shifts/EditShift')
);

// Material Categories.
const ItemCategories = React.lazy(() =>
  import('../../views/Configurator/Item/ItemCategories/index')
);

const AddItemCategory = React.lazy(() =>
  import('../../views/Configurator/Item/ItemCategories/AddItemCategory')
);

const EditItemCategory = React.lazy(() =>
  import('../../views/Configurator/Item/ItemCategories/EditItemCategory')
);

const ViewItemCategory = React.lazy(() =>
  import('../../views/Configurator/Item/ItemCategories/ViewItemCategory')
);

// -- Scales.
const Scales = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Scales/')
);

const AddScale = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Scales/AddScale')
);

const EditScale = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Scales/EditScale')
);

const ViewScale = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Scales/ViewScale')
);

// -- Bins.
const Bins = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Bins/')
);

const AddBin = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Bins/AddBin')
);

const EditBin = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Bins/EditBin')
);

const ViewBin = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/Bins/ViewBin')
);

// -- Dosing Points.
const DosingPoints = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/DosingPoints/')
);

const AddDosingPoint = React.lazy(() =>
  import(
    '../../views/Configurator/DosingAndFeeding/DosingPoints/AddDosingPoint'
  )
);

const EditDosingPoint = React.lazy(() =>
  import(
    '../../views/Configurator/DosingAndFeeding/DosingPoints/EditDosingPoint'
  )
);

const ViewDosingPoint = React.lazy(() =>
  import(
    '../../views/Configurator/DosingAndFeeding/DosingPoints/ViewDosingPoint'
  )
);

// -- Feeding Points.
const FeedingPoints = React.lazy(() =>
  import('../../views/Configurator/DosingAndFeeding/FeedingPoints/')
);

const AddFeedingPoint = React.lazy(() =>
  import(
    '../../views/Configurator/DosingAndFeeding/FeedingPoints/AddFeedingPoint'
  )
);

const EditFeedingPoint = React.lazy(() =>
  import(
    '../../views/Configurator/DosingAndFeeding/FeedingPoints/EditFeedingPoint'
  )
);

const ViewFeedingPoint = React.lazy(() =>
  import(
    '../../views/Configurator/DosingAndFeeding/FeedingPoints/ViewFeedingPoint'
  )
);

// -- Mixing Action Parameter
const ActionParameter = React.lazy(() =>
  import('../../views/Configurator/MixerMode/ActionParameter/')
);

const AddActionParameters = React.lazy(() =>
  import(
    '../../views/Configurator/MixerMode/ActionParameter/AddActionParameters'
  )
);

const EditActionParameters = React.lazy(() =>
  import(
    '../../views/Configurator/MixerMode/ActionParameter/EditActionParameters'
  )
);

const ViewActionParameters = React.lazy(() =>
  import(
    '../../views/Configurator/MixerMode/ActionParameter/ViewActionParameters'
  )
);

// -- Mixing Mode Parameter.

const ModeParameter = React.lazy(() =>
  import('../../views/Configurator/MixerMode/ModeParameter/')
);

const EditModeParameters = React.lazy(() =>
  import('../../views/Configurator/MixerMode/ModeParameter/EditModeParameters')
);

const ViewModeParameters = React.lazy(() =>
  import('../../views/Configurator/MixerMode/ModeParameter/ViewModeParameters')
);

const AddModeParameters = React.lazy(() =>
  import('../../views/Configurator/MixerMode/ModeParameter/AddModeParameters')
);

// IRam
const AddNewIram = React.lazy(() =>
  import('../../views/Configurator/MixerMode/Iram/AddNewIram')
);

// Edit IRam
const EditIram = React.lazy(() =>
  import('../../views/Configurator/MixerMode/Iram/EditIram')
);

const Iram = React.lazy(() =>
  import('../../views/Configurator/MixerMode/Iram/index')
);

const ViewIram = React.lazy(() =>
  import('../../views/Configurator/MixerMode/Iram/ViewIram')
);

// -- Mixing Action
const MixingAction = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingAction/')
);

const EditMixingAction = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingAction/EditMixingAction')
);

const ViewMixingAction = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingAction/ViewMixingAction')
);

const NewMixingAction = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingAction/AddMixingAction')
);

// -- Mixing Mode
const MixingMode = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingMode/')
);

const AddMixingMode = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingMode/AddMixingMode')
);

const EditMixingMode = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingMode/EditMixingMode')
);

const ViewMixingMode = React.lazy(() =>
  import('../../views/Configurator/MixerMode/MixingMode/ViewMixingMode')
);

// Miscellaneous Parameters
const MiscellaneousParams = React.lazy(() =>
  import('../../views/Configurator/MiscellaneousParameters')
);

// Miscellaneous Parameters edit
const EditMiscellaneousParams = React.lazy(() =>
  import(
    '../../views/Configurator/MiscellaneousParameters/EditMiscellaneousParameters'
  )
);

// View Miscellaneous Params
const ViewMiscellaneousParams = React.lazy(() =>
  import(
    '../../views/Configurator/MiscellaneousParameters/ViewMiscellaneousParameters'
  )
);

const AddMiscellaneousParameters = React.lazy(() =>
  import(
    '../../views/Configurator/MiscellaneousParameters/AddMiscellaneousParameters'
  )
);

// Report Parameters
const ReportParams = React.lazy(() =>
  import('../../views/Configurator/ReportParameters')
);

// Add Report Parameters
const AddReportParameters = React.lazy(() =>
  import('../../views/Configurator/ReportParameters/AddReportParameters')
);

// Edit Report Parameters
const EditReportParameters = React.lazy(() =>
  import('../../views/Configurator/ReportParameters/EditReportParameters')
);

// View Report Parameters
const ViewReportParameters = React.lazy(() =>
  import('../../views/Configurator/ReportParameters/ViewReportParameters')
);

// Engineering Parameters
const EngineeringParams = React.lazy(() =>
  import('../../views/Configurator/EngineeringParameters')
);

// Items
const Items = React.lazy(() =>
  import('../../views/ItemManagement/Items/index')
);

const ViewItem = React.lazy(() =>
  import('../../views/ItemManagement/Items/ViewItem')
);

const AddItem = React.lazy(() =>
  import('../../views/ItemManagement/Items/AddItem')
);

const EditItem = React.lazy(() =>
  import('../../views/ItemManagement/Items/EditItem')
);

// BOM
const BOM = React.lazy(() => import('../../views/ItemManagement/BOM/index'));

const ViewBOM = React.lazy(() =>
  import('../../views/ItemManagement/BOM/ViewBOM')
);

const AddBOM = React.lazy(() =>
  import('../../views/ItemManagement/BOM/AddBOM')
);

const EditBOM = React.lazy(() =>
  import('../../views/ItemManagement/BOM/EditBOM')
);

const BinAssignment = React.lazy(() =>
  import('../../views/StorageManagement/BinAssignment')
);

//Vessel Group
const CreateVesselGroup = React.lazy(() =>
  import('../../views/StorageManagement/VesselGroup/VesselGroupCreate')
);

const ListVesselGroup = React.lazy(() =>
  import('../../views/StorageManagement/VesselGroup/VesselGroupList')
);

const EditVesselGroup = React.lazy(() =>
  import('../../views/StorageManagement/VesselGroup/VesselGroupEdit')
);

const ViewVesselGroup = React.lazy(() =>
  import('../../views/StorageManagement/VesselGroup/VesselGroupView')
);

const ManualTransaction = React.lazy(() =>
  import('../../views/StorageManagement/manual_transaction/inventories')
);

const AddEngineeringParameters = React.lazy(() =>
  import(
    '../../views/Configurator/EngineeringParameters/AddEngineeringParameters'
  )
);

const EditEngineeringParameters = React.lazy(() =>
  import(
    '../../views/Configurator/EngineeringParameters/EditEngineeringParameters'
  )
);

const ViewEngineeringParameters = React.lazy(() =>
  import(
    '../../views/Configurator/EngineeringParameters/ViewEngineeringParameters'
  )
);

// Storage Location
const StorageLocations = React.lazy(() =>
  import('../../views/InventoryManagement/StorageLocation')
);

const ViewStorageLocation = React.lazy(() =>
  import('../../views/InventoryManagement/StorageLocation/ViewStorageLocation')
);

const AddStorageLocation = React.lazy(() =>
  import('../../views/InventoryManagement/StorageLocation/AddStorageLocation')
);

const EditStorageLocation = React.lazy(() =>
  import('../../views/InventoryManagement/StorageLocation/EditStorageLocation')
);

// Vendor
const Vendors = React.lazy(() => import('../../views/ItemManagement/Vendor'));

const ViewVendor = React.lazy(() =>
  import('../../views/ItemManagement/Vendor/ViewVendor')
);

const AddVendor = React.lazy(() =>
  import('../../views/ItemManagement/Vendor/AddVendor')
);

const EditVendor = React.lazy(() =>
  import('../../views/ItemManagement/Vendor/EditVendor')
);

//Stop Time Views

const StopTimeReasons = React.lazy(() =>
  import('../../views/Configurator/StopTime/StopTimeReasons')
);

const ViewStopTimeReasons = React.lazy(() =>
  import(
    '../../views/Configurator/StopTime/StopTimeReasons/ViewStopTimeReasons'
  )
);

const AddStopTimeReasons = React.lazy(() =>
  import('../../views/Configurator/StopTime/StopTimeReasons/AddStopTimeReasons')
);

const EditStopTimeReasons = React.lazy(() =>
  import(
    '../../views/Configurator/StopTime/StopTimeReasons/EditStopTimeReasons'
  )
);

const Recipes = React.lazy(() => import('../../views/Recipes/'));

const AddRecipe = React.lazy(() => import('../../views/Recipes/AddRecipe'));

const EditRecipe = React.lazy(() => import('../../views/Recipes/EditRecipe'));

const ViewRecipe = React.lazy(() => import('../../views/Recipes/ViewRecipe'));

const SaveAsRecipe = React.lazy(() =>
  import('../../views/Recipes/SaveAsRecipe')
);

const AddProductionSchedule = React.lazy(() =>
  import('../../views/Scheduling/ProductionSchedules/AddProductionSchedule')
);

const EditProductionSchedule = React.lazy(() =>
  import('../../views/Scheduling/ProductionSchedules/EditProductionSchedule')
);

const ProductionSchedules = React.lazy(() =>
  import('../../views/Scheduling/ProductionSchedules')
);

const ViewProductionSchedule = React.lazy(() =>
  import('../../views/Scheduling/ProductionSchedules/ViewProductionSchedule')
);

const ByItems = React.lazy(() =>
  import('../../views/Reporting/Consumption/ByItems')
);

const ConsumptionStatistics = React.lazy(() =>
  import('../../views/Reporting/Consumption/ConsumptionStatistics')
);

const ByItem = React.lazy(() =>
  import('../../views/Reporting/Consumption/ByItem')
);

const ByBatches = React.lazy(() =>
  import('../../views/Reporting/Consumption/ByBatches')
);

const ByItemCategory = React.lazy(() =>
  import('../../views/Reporting/Consumption/ByItemCategory')
);

const ProductionByRecipe = React.lazy(() =>
  import('../../views/Reporting/Production/ProductionByRecipe')
);

const ProductionByTime = React.lazy(() =>
  import('../../views/Reporting/Production/ProductionByTime')
);

const LineDetails = React.lazy(() =>
  import('../../views/Reporting/Production/linedetails')
);

const BatchSummary = React.lazy(() =>
  import('../../views/Reporting/Batch/BatchSummary')
);

const BatchStatistics = React.lazy(() =>
  import('../../views/Reporting/Batch/BatchStatistics')
);

const BatchDetails = React.lazy(() =>
  import('../../views/Reporting/Batch/BatchDetails')
);

const AlarmDetails = React.lazy(() =>
  import('../../views/Reporting/Batch/AlarmDetails')
);

const DiagnosticsParameters = React.lazy(() =>
  import('../../views/Reporting/Diagnostics/DiagnosticsParameters')
);

const DownstreamParameters = React.lazy(() =>
  import('../../views/Reporting/Diagnostics/DownstreamParameters')
);

const Realtime = React.lazy(() =>
  import('../../views/Reporting/Diagnostics/Realtime')
);

const OutOfSpec = React.lazy(() =>
  import('../../views/Reporting/Diagnostics/OutOfSpec')
);

const CompareReportparameters = React.lazy(() =>
  import('../../views/Reporting/Diagnostics/CompareReportparameters')
);

const Users = React.lazy(() => import('../../views/UserManagement/Users'));

const CreateUser = React.lazy(() =>
  import('../../views/UserManagement/CreateUser')
);

const EditUser = React.lazy(() =>
  import('../../views/UserManagement/EditUser')
);

const CopyParameterRouter = React.lazy(() =>
  import('../../views/Utils/CopyParams')
);

const pcsReport = React.lazy(() => import('../../views/Reporting/Pcs/report'));

const pcsPressure = React.lazy(() =>
  import('../../views/Reporting/Pcs/pressure')
);

const consumptionStatisticsOrderWiseGraphRoute = React.lazy(() =>
  import('../../views/Reporting/Consumption/ConsumptionStatisticGraph')
);

const batchStatisticsOrderWiseGraphRoute = React.lazy(() =>
  import('../../views/Reporting/Batch/BatchStatisticGraph')
);

// Bin history
const BinHistory = React.lazy(() =>
  import('../../views/Reporting/Others/BinHistory/binHistory')
);

const StopTimeReport = React.lazy(() =>
  import('../../views/Reporting/Others/stopTimeReport')
);

const importRoute = React.lazy(() => import('../../views/Import/import'));

const StopTimeGroupRoutes = React.lazy(() =>
  import('../../views/Configurator/StopTime/stopTimeGroup')
);
const AddStopTimeGroupRoutes = React.lazy(() =>
  import('../../views/Configurator/StopTime/stopTimeGroup/AddGroup')
);
const EditStopTimeGroupRoutes = React.lazy(() =>
  import('../../views/Configurator/StopTime/stopTimeGroup/EditGroup')
);
const ViewStopTimeGroupRoutes = React.lazy(() =>
  import('../../views/Configurator/StopTime/stopTimeGroup/ViewGroup')
);

//Units
const Unit = React.lazy(() => import('../../views/Configurator/Units'));

const AddNewUnit = React.lazy(() =>
  import('../../views/Configurator/Units/AddNewUnit')
);

const EditUnit = React.lazy(() =>
  import('../../views/Configurator/Units/EditUnit')
);

const ViewUnit = React.lazy(() =>
  import('../../views/Configurator/Units/ViewUnit')
);

// Vessels
const VesselList = React.lazy(() => import('../../views/Configurator/vessel'));

const CreateVessel = React.lazy(() =>
  import('../../views/Configurator/vessel/create_vessel')
);

const EditVessel = React.lazy(() =>
  import('../../views/Configurator/vessel/edit_vessel')
);

const ViewVessel = React.lazy(() =>
  import('../../views/Configurator/vessel/view_vessel')
);

// Vessel to Vessel Assignments.
const VesselToVesselAssignment = React.lazy(() =>
  import('../../views/Configurator/assignments/vessel_to_vessel')
);

// Line to Vessel Assignments.
const LineToVesselAssignment = React.lazy(() =>
  import('../../views/Configurator/assignments/line_to_vessel')
);

// Action to Vessel Assignments.
const ActionToVesselAssignment = React.lazy(() =>
  import('../../views/Configurator/assignments/action_to_vessel')
);

const ItemClassRoute = React.lazy(() =>
  import('../../views/Configurator/Item/ItemClass/index')
);

const EditItemClassRoutes = React.lazy(() =>
  import('../../views/Configurator/Item/ItemClass/EditItemClass')
);

const ViewItemClassRoutes = React.lazy(() =>
  import('../../views/Configurator/Item/ItemClass/ViewItemClass')
);

const ItemPropertyRoute = React.lazy(() =>
  import('../../views/Configurator/Item/ItemProperties/index')
);

const AddItemPropertyRoute = React.lazy(() =>
  import('../../views/Configurator/Item/ItemProperties/AddItemProperty')
);

const EditItemPropertyRoutes = React.lazy(() =>
  import('../../views/Configurator/Item/ItemProperties/EditItemProperty')
);

const ViewItemPropertyRoutes = React.lazy(() =>
  import('../../views/Configurator/Item/ItemProperties/ViewItemProperty')
);

const CarrierTypeRoute = React.lazy(() =>
  import('../../views/InventoryManagement/CarrierType/carrier_type_list_view')
);

const AddCarrierTypeRoute = React.lazy(() =>
  import('../../views/InventoryManagement/CarrierType/add_new_carrier_type')
);

const EditCarrierTypeRoutes = React.lazy(() =>
  import('../../views/InventoryManagement/CarrierType/edit_carrier_type')
);

const ViewCarrierTypeRoutes = React.lazy(() =>
  import('../../views/InventoryManagement/CarrierType/view_carrier_type')
);

const StorageAreaRoute = React.lazy(() =>
  import('../../views/InventoryManagement/StorageArea/storage_area_list_view')
);

const AddStorageAreaRoute = React.lazy(() =>
  import('../../views/InventoryManagement/StorageArea/add_new_storage_area')
);

const EditStorageAreaRoutes = React.lazy(() =>
  import('../../views/InventoryManagement/StorageArea/edit_storage_area')
);

const ViewStorageAreaRoutes = React.lazy(() =>
  import('../../views/InventoryManagement/StorageArea/view_storage_area')
);

const CarrierRoute = React.lazy(() =>
  import('../../views/InventoryManagement/Carrier/carrier_list_view.js')
);

const AddCarrierRoute = React.lazy(() =>
  import('../../views/InventoryManagement/Carrier/add_new_carrier')
);

const EditCarrierRoutes = React.lazy(() =>
  import('../../views/InventoryManagement/Carrier/edit_carrier')
);

const ViewCarrierRoutes = React.lazy(() =>
  import('../../views/InventoryManagement/Carrier/view_carrier')
);

//GRN Routes
const GRNRoute = React.lazy(() =>
  import('../../views/InventoryManagement/GRN/grn_list_view')
);

const AddGRNRoute = React.lazy(() =>
  import('../../views/InventoryManagement/GRN/add_new_grn')
);

const EditGRNRoute = React.lazy(() =>
  import('../../views/InventoryManagement/GRN/edit_grn')
);

const ViewGRNRoute = React.lazy(() =>
  import('../../views/InventoryManagement/GRN/view_grn')
);

const MaterialTransferUnitRoute = React.lazy(() =>
  import(
    '../../views/InventoryManagement/MaterialTransferUnit/material_transfer_unit_list_view'
  )
);

const AddMaterialTransferUnitRoute = React.lazy(() =>
  import(
    '../../views/InventoryManagement/MaterialTransferUnit/add_new_material_transfer_unit'
  )
);

const EditMaterialTransferUnitRoute = React.lazy(() =>
  import(
    '../../views/InventoryManagement/MaterialTransferUnit/edit_material_transfer_unit'
  )
);

const ViewMaterialTransferUnitRoute = React.lazy(() =>
  import(
    '../../views/InventoryManagement/MaterialTransferUnit/view_material_transfer_unit'
  )
);

//Transfer Order Routes

const TransferOrderRoute = React.lazy(() =>
  import(
    '../../views/InventoryManagement/TransferOrder/transfer_order_list_view.js'
  )
);

const AddTransferOrderRoute = React.lazy(() =>
  import('../../views/InventoryManagement/TransferOrder/add_new_transfer_order')
);

const EditTransferOrderRoutes = React.lazy(() =>
  import('../../views/InventoryManagement/TransferOrder/edit_transfer_order')
);

const ViewTransferOrderRoutes = React.lazy(() =>
  import('../../views/InventoryManagement/TransferOrder/view_transfer_order')
);

const LotWiseTracebilityIndexRoute = React.lazy(() =>
  import('../../views/Reporting/Traceability/LotWise/list_view.js')
);

const TraceabilityTrackingRoute = React.lazy(() =>
  import('../../views/Reporting/Traceability/TreeView/tracebility_view.js')
);

//Inventoy Correction Routes

//GRN Correction Order Routes

const GRNCorrectionRoute = React.lazy(() =>
  import(
    '../../views/InventoryManagement/InventoryCorrection/GRNCorrection/grn_correction_list_view'
  )
);

const EditGRNCorrectionRoute = React.lazy(() =>
  import(
    '../../views/InventoryManagement/InventoryCorrection/GRNCorrection/edit_grn_correction'
  )
);

//MTU Correction Order Routes

const MTUCorrectionRoute = React.lazy(() =>
  import(
    '../../views/InventoryManagement/InventoryCorrection/MTUCorrection/mtu_correction_list_view'
  )
);

const EditMTUCorrectionRoute = React.lazy(() =>
  import(
    '../../views/InventoryManagement/InventoryCorrection/MTUCorrection/edit_mtu_correction'
  )
);

//Bin Lot COrrection Routes
const BinLotCorrection = React.lazy(() =>
  import(
    '../../views/StorageManagement/BinLot/BinLotCorrection/bin_lot_correction'
  )
);

const EditBinLotCorrection = React.lazy(() =>
  import(
    '../../views/StorageManagement/BinLot/BinLotCorrection/edit_bin_lot_correction'
  )
);

const InventoryReport = React.lazy(() =>
  import('../../views/Reporting/Inventory/Report/inventory_report')
);

const InventoryAlert = React.lazy(() =>
  import('../../views/Reporting/Inventory/Alert/inventory_alert')
);

//Subscribers Route

const InventoryAlertSubscriberRoute = React.lazy(() =>
  import(
    '../../views/Configurator/Inventory/Inventory_Alert_Subscriber/subscriber_list_view'
  )
);

const AddInventoryAlertSubscriberRoute = React.lazy(() =>
  import(
    '../../views/Configurator/Inventory/Inventory_Alert_Subscriber/add_new_subscriber'
  )
);

const EditInventoryAlertSubscriberRoutes = React.lazy(() =>
  import(
    '../../views/Configurator/Inventory/Inventory_Alert_Subscriber/edit_subscriber'
  )
);

const ViewInventoryAlertSubscriberRoutes = React.lazy(() =>
  import(
    '../../views/Configurator/Inventory/Inventory_Alert_Subscriber/view_subscriber'
  )
);

const GeneralSettings = React.lazy(() =>
  import('../../views/GeneralSettings/general_settings')
);

//Barcode Configuration Routes

const BarcodeConfigurationRoute = React.lazy(() =>
  import(
    '../../views/Configurator/Inventory/Barcode_Configuration/barcode_configuration_list_view'
  )
);

const AddBarcodeConfigurationRoute = React.lazy(() =>
  import(
    '../../views/Configurator/Inventory/Barcode_Configuration/add_new_barcode_configuration'
  )
);

const EditBarcodeConfigurationRoutes = React.lazy(() =>
  import(
    '../../views/Configurator/Inventory/Barcode_Configuration/edit_barcode_configuration'
  )
);

const ViewBarcodeConfigurationRoutes = React.lazy(() =>
  import(
    '../../views/Configurator/Inventory/Barcode_Configuration/view_barcode_configurator.js'
  )
);

const BatchTraceabilityIndexRoute = React.lazy(() =>
  import('../../views/Reporting/Traceability/BatchWise/list_view.js')
);

const PrioritySelection = React.lazy(() =>
  import(
    '../../views/Configurator/Inventory/PrioritySelection/priority_selection'
  )
);

const StorageWiseInventoryReport = React.lazy(() =>
  import(
    '../../views/Reporting/Inventory/StorageWise/storage_wise_inventory_report.js'
  )
);

const EngineeringParametersChangeReport = React.lazy(() =>
  import(
    '../../views/Reporting/Change Report/EngineeringParameterChangeReport.js'
  )
);

const OnlineRecipeChangeReport = React.lazy(() =>
  import('../../views/Reporting/Change Report/OnlineRecipeChangeReport')
);

const ScrapBookRoute = React.lazy(() =>
  import('../../views/InventoryManagement/ScrapMtuLedger/list_view')
);

//Bin Lot Route
const BinLotRoute = React.lazy(() =>
  import('../../views/InventoryManagement/BinLot/bin_lot')
);

const MobileParameterRoutes = React.lazy(() =>
  import('../../views/Configurator/MobileParameters')
);
const AddMobileParameterRoutes = React.lazy(() =>
  import('../../views/Configurator/MobileParameters/AddNewMobileParameter')
);
const EditMobileParameterRoutes = React.lazy(() =>
  import('../../views/Configurator/MobileParameters/EditMobileParameter')
);
const ViewMobileParameterRoutes = React.lazy(() =>
  import('../../views/Configurator/MobileParameters/ViewMobileParameter')
);

const LimitRoute = React.lazy(() =>
  import('../../views/Configurator/Limit/limit_list_view')
);

const EditLimitRoute = React.lazy(() =>
  import('../../views/Configurator/Limit/edit_limit')
);

//Bin Lot COrrection Routes
const UserGroup = React.lazy(() =>
  import('../../views/UserGroups/user_group_list_view')
);

const EditUserGroup = React.lazy(() =>
  import('../../views/UserGroups/edit_user_group')
);

const ViewUserGroup = React.lazy(() =>
  import('../../views/UserGroups/view_user_group')
);

const CreateUserGroup = React.lazy(() =>
  import('../../views/UserGroups/add_new_user_group')
);

const PageNotFound = React.lazy(() => import('../../views/404/404'));

// TODO: Create an unauthorized component
// const Unauthorized = Loadable({
//   loader: () => import('../../views/UserManagement/CreateUser'),
//   loading: Loading
// });

// Routes for a super user.
const routes = [
  {
    path: '/',
    exact: true,
    name: <I18nMessage id="Home" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: Application
  },
  {
    path: '/help',
    exact: true,
    name: <I18nMessage id="Help" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: Help
  },
  {
    path: '/help/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: AddNewHelp
  },
  {
    path: '/help/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ViewHelp
  },
  {
    path: '/help/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: EditHelp
  },
  {
    path: '/dashboard',
    name: <I18nMessage id="Dashboard" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: Dashboard
  },
  {
    path: '/configurator',
    exact: true,
    name: <I18nMessage id="Configurator" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN
  },
  {
    path: '/configurator/line',
    exact: true,
    name: <I18nMessage id="Line" />,
    privileges: ['CONFIGURATOR_READ'],
    role: ROLES.SUPERADMIN_ADMIN,
    component: Line
  },
  {
    path: '/configurator/Shift',
    exact: true,
    name: <I18nMessage id="Shift" />,
    privileges: ['CONFIGURATOR_READ'],
    role: ROLES.SUPERADMIN_ADMIN,
    component: Shift
  },

  {
    path: '/configurator/shift/add',
    exact: true,
    name: <I18nMessage id="Add Shift" />,
    privileges: ['CONFIGURATOR_CREATE'],
    role: ROLES.SUPERADMIN_ADMIN,
    component: AddShift
  },

  {
    path: '/configurator/shift/edit',
    exact: true,
    name: <I18nMessage id="Edit Shift" />,
    privileges: ['CONFIGURATOR_UPDATE'],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditShift
  },

  {
    path: '/configurator/line/new',
    name: <I18nMessage id="New" />,
    privileges: ['_LINE_CREATE'],
    role: ROLES.SUPERADMIN,
    component: AddNewLine
  },
  {
    path: '/configurator/line/view/:id',
    name: <I18nMessage id="View" />,
    privileges: ['_LINE_UPDATE'],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ViewLine
  },
  {
    path: '/configurator/line/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: ['_LINE_UPDATE'],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditLine
  },
  {
    path: '/configurator/unit',
    exact: true,
    name: <I18nMessage id="Unit" />,
    privileges: ['CONFIGURATOR_READ'],
    role: ROLES.SUPERADMIN_ADMIN,
    component: Unit
  },
  {
    path: '/configurator/unit/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: AddNewUnit
  },
  {
    path: '/configurator/unit/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ViewUnit
  },
  {
    path: '/configurator/unit/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditUnit
  },
  {
    path: '/configurator/vessel',
    exact: true,
    name: <I18nMessage id="Vessel" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: VesselList
  },
  {
    path: '/configurator/vessel/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: CreateVessel
  },
  {
    path: '/configurator/vessel/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ViewVessel
  },
  {
    path: '/configurator/assignments',
    exact: true,
    name: <I18nMessage id="Assignments" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: VesselToVesselAssignment
  },
  {
    path: '/configurator/assignments/vessel-to-vessel',
    name: <I18nMessage id="Vessel to Vessel" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: VesselToVesselAssignment
  },
  {
    path: '/configurator/assignments/line-to-vessel',
    name: <I18nMessage id="Vessel to Line" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: LineToVesselAssignment
  },
  {
    path: '/configurator/assignments/action-to-vessel',
    name: <I18nMessage id="Action to Vessel" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ActionToVesselAssignment
  },
  {
    path: '/configurator/vessel/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditVessel
  },
  {
    path: '/configurator/item/itemcategories',
    exact: true,
    name: <I18nMessage id="Item Categories" />,
    privileges: ['CONFIGURATOR_READ'],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ItemCategories
  },
  {
    path: '/configurator/item/itemcategories/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: AddItemCategory
  },
  {
    path: '/configurator/item/itemcategories/edititemcategory/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditItemCategory
  },
  {
    path: '/configurator/item/itemcategories/viewitemcategory/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ViewItemCategory
  },

  {
    path: '/configurator/dosingandfeeding',
    exact: true,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    name: <I18nMessage id="Dosing and Feeding" />
  },
  {
    path: '/configurator/dosingandfeeding/scales',
    exact: true,
    name: <I18nMessage id="Scales" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: Scales
  },
  {
    path: '/configurator/dosingandfeeding/scales/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: AddScale
  },
  {
    path: '/configurator/dosingandfeeding/scales/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditScale
  },
  {
    path: '/configurator/dosingandfeeding/scales/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ViewScale
  },
  {
    path: '/configurator/dosingandfeeding/bins',
    exact: true,
    name: <I18nMessage id="Bins" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: Bins
  },
  {
    path: '/configurator/dosingandfeeding/bins/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: AddBin
  },
  {
    path: '/configurator/dosingandfeeding/bins/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditBin
  },
  {
    path: '/configurator/dosingandfeeding/bins/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ViewBin
  },
  {
    path: '/configurator/dosingandfeeding/dosingpoints',
    exact: true,
    name: <I18nMessage id="Dosing Points" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: DosingPoints
  },
  {
    path: '/configurator/dosingandfeeding/dosingpoints/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: AddDosingPoint
  },
  {
    path: '/configurator/dosingandfeeding/dosingpoints/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditDosingPoint
  },
  {
    path: '/configurator/dosingandfeeding/dosingpoints/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ViewDosingPoint
  },
  {
    path: '/configurator/dosingandfeeding/feedingpoints',
    exact: true,
    name: <I18nMessage id="Feeding Points" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: FeedingPoints
  },
  {
    path: '/configurator/dosingandfeeding/feedingpoints/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: AddFeedingPoint
  },
  {
    path: '/configurator/dosingandfeeding/feedingpoints/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditFeedingPoint
  },
  {
    path: '/configurator/dosingandfeeding/feedingpoints/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ViewFeedingPoint
  },
  {
    path: '/configurator/mixermode',
    exact: true,
    name: <I18nMessage id="Mixing" />,
    privileges: [],
    role: ['_SUPER', 'ADMIN']
  },
  {
    path: '/configurator/mixermode/actionparameter',
    exact: true,
    name: <I18nMessage id="Action Prameters" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ActionParameter
  },
  {
    path: '/configurator/mixermode/actionparameter/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: AddActionParameters
  },
  {
    path: '/configurator/mixermode/actionparameter/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditActionParameters
  },
  {
    path: '/configurator/mixermode/actionparameter/view/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ViewActionParameters
  },
  {
    path: '/configurator/mixermode/modeparameter',
    exact: true,
    name: <I18nMessage id="Mode Prameters" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ModeParameter
  },
  {
    path: '/configurator/mixermode/modeparameter/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: AddModeParameters
  },
  {
    path: '/configurator/mixermode/modeparameter/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditModeParameters
  },
  {
    path: '/configurator/mixermode/modeparameter/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ViewModeParameters
  },
  {
    path: '/configurator/mixermode/iram',
    exact: true,
    name: <I18nMessage id="Iram" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: Iram
  },
  {
    path: '/configurator/mixermode/iram/new',
    exact: true,
    name: <I18nMessage id="Add Iram" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: AddNewIram
  },
  {
    path: '/configurator/mixermode/iram/view/:id',
    exact: true,
    name: <I18nMessage id="View Iram" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ViewIram
  },
  {
    path: '/configurator/mixermode/iram/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit Iram" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditIram
  },
  {
    path: '/configurator/mixermode/mixingaction',
    exact: true,
    name: <I18nMessage id="Action" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: MixingAction
  },
  {
    path: '/configurator/mixermode/mixingaction/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: NewMixingAction
  },
  {
    path: '/configurator/mixermode/mixingaction/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditMixingAction
  },
  {
    path: '/configurator/mixermode/mixingaction/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ViewMixingAction
  },
  {
    path: '/configurator/mixermode/mixingmode',
    exact: true,
    name: <I18nMessage id="Mode" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: MixingMode
  },
  {
    path: '/configurator/mixermode/mixingmode/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: AddMixingMode
  },
  {
    path: '/configurator/mixermode/mixingmode/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditMixingMode
  },
  {
    path: '/configurator/mixermode/mixingmode/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: ViewMixingMode
  },
  {
    path: '/configurator/additionalparameters',
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    name: <I18nMessage id="Additional Parameters" />
  },
  {
    path: '/configurator/additionalparameters/miscellaneousparameters',
    exact: true,
    name: <I18nMessage id="Miscellaneous Parameters" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: MiscellaneousParams
  },
  {
    path: '/configurator/additionalparameters/miscellaneousparameters/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: EditMiscellaneousParams
  },
  {
    path: '/configurator/additionalparameters/miscellaneousparameters/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ViewMiscellaneousParams
  },
  {
    path: '/configurator/additionalparameters/miscellaneousparameters/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: AddMiscellaneousParameters
  },
  {
    path: '/configurator/additionalparameters/engineeringparameters',
    exact: true,
    name: <I18nMessage id="Engineering Parameters" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: EngineeringParams
  },
  {
    path: '/configurator/additionalparameters/engineeringparameters/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: AddEngineeringParameters
  },
  {
    path: '/configurator/additionalparameters/engineeringparameters/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: EditEngineeringParameters
  },
  {
    path: '/configurator/additionalparameters/engineeringparameters/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ViewEngineeringParameters
  },
  {
    path: '/configurator/additionalparameters/reportparameters',
    exact: true,
    name: <I18nMessage id="Report Parameters" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ReportParams
  },
  {
    path: '/configurator/additionalparameters/reportparameters/new',
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: AddReportParameters
  },
  {
    path: '/configurator/additionalparameters/reportparameters/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: EditReportParameters
  },
  {
    path: '/configurator/additionalparameters/reportparameters/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ViewReportParameters
  },
  {
    path: '/configurator/stoptime/stoptimereasons',
    exact: true,
    name: <I18nMessage id="Stop Reasons" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: StopTimeReasons
  },
  {
    path: '/configurator/stoptime/stoptimereasons/add',
    name: <I18nMessage id="Add" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    exact: true,
    component: AddStopTimeReasons
  },
  {
    path: '/configurator/stoptime/stoptimereasons/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    exact: true,
    component: EditStopTimeReasons
  },
  {
    path: '/configurator/stoptime/stoptimereasons/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    exact: true,
    component: ViewStopTimeReasons
  },
  {
    path: '/itemmanagement/items',
    name: <I18nMessage id="Items" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: Items
  },
  {
    path: '/itemmanagement/items/view/:id',
    name: <I18nMessage id="View" />,
    exact: true,
    privileges: ['ITEM_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewItem
  },
  {
    name: <I18nMessage id="Edit" />,
    path: '/itemmanagement/items/edit/:id',
    exact: true,
    privileges: ['ITEM_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditItem
  },
  {
    path: '/itemmanagement/items/new',
    name: <I18nMessage id="New" />,
    exact: true,
    privileges: ['ITEM_MANAGEMENT_CREATE'],
    role: ROLES.ALL_ROLES,
    component: AddItem
  },
  {
    path: '/itemmanagement/bom',
    name: <I18nMessage id="BOM" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: BOM
  },
  {
    path: '/itemmanagement/bom/view/:id',
    name: <I18nMessage id="View" />,
    exact: true,
    privileges: ['ITEM_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewBOM
  },
  {
    name: <I18nMessage id="Edit" />,
    path: '/itemmanagement/bom/edit/:id',
    exact: true,
    privileges: ['ITEM_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditBOM
  },
  {
    path: '/itemmanagement/bom/new',
    name: <I18nMessage id="New" />,
    exact: true,
    privileges: ['ITEM_MANAGEMENT_CREATE'],
    role: ROLES.ALL_ROLES,
    component: AddBOM
  },
  {
    path: '/storage-management/bin-assignment',
    name: <I18nMessage id="Bin Assignment" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: BinAssignment
  },
  {
    path: '/storage-management/manualtransaction',
    name: <I18nMessage id="Manual Transaction" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ManualTransaction
  },
  {
    path: '/storage-management/bin-lot/bin-lot-correction',
    exact: true,
    name: <I18nMessage id="Bin Lot Correction" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: BinLotCorrection
  },
  {
    path: '/storage-management/bin-lot/bin-lot-correction/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: ['INVENTORY_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditBinLotCorrection
  },
  {
    path: '/storage-management/vessel-group',
    name: <I18nMessage id="Vessel Group" />,
    exact: true,
    privileges: [],
    role: ROLES.SUPERADMIN,
    component: ListVesselGroup
  },
  {
    path: '/storage-management/vessel-group/create',
    name: <I18nMessage id="Create" />,
    privileges: [],
    role: ROLES.SUPERADMIN,
    component: CreateVesselGroup
  },
  {
    path: '/storage-management/vessel-group/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN,
    component: EditVesselGroup
  },
  {
    path: '/storage-management/vessel-group/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.SUPERADMIN,
    component: ViewVesselGroup
  },
  {
    path: '/recipemanagement/recipes',
    name: <I18nMessage id="Recipes" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: Recipes
  },
  {
    path: '/recipemanagement/recipes/addrecipe',
    name: <I18nMessage id="Add" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: AddRecipe
  },
  {
    path: '/recipemanagement/recipes/editrecipe/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: EditRecipe
  },
  {
    path: '/recipemanagement/recipes/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ViewRecipe
  },
  {
    path: '/recipemanagement/recipes/view/:id/:versionId',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ViewRecipe
  },
  {
    path: '/recipemanagement/recipes/:id/saveAs',
    name: <I18nMessage id="Save As" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: SaveAsRecipe
  },
  {
    path: '/scheduling/productionschedules/view/:id',
    name: <I18nMessage id="View" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ViewProductionSchedule
  },
  {
    path: '/scheduling/productionschedules/new',
    name: <I18nMessage id="Add" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: AddProductionSchedule
  },
  {
    path: '/scheduling/productionschedules/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: EditProductionSchedule
  },
  {
    path: '/scheduling/productionschedules',
    name: <I18nMessage id="Production Schedules" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ProductionSchedules
  },
  {
    path: '/reporting/consumption/byitem/:lineid/:id/:fromdatetime/:todatetime',
    exact: true,
    name: <I18nMessage id="Item Consumption" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ByItem
  },
  {
    path:
      '/reporting/consumption/byitems/:lineid/:materialcategoryid/:fromdatetime/:todatetime',
    exact: true,
    name: <I18nMessage id="Item Consumptions" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ByItems
  },
  {
    path: '/reporting/consumption/byitems',
    exact: true,
    name: <I18nMessage id="All Item Consumptions" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ByItems
  },
  {
    path: '/reporting/consumption/byitemcategory',
    exact: true,
    name: <I18nMessage id="Item Category Consumption" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ByItemCategory
  },
  {
    path: '/reporting/consumption/byitem',
    exact: true,
    name: <I18nMessage id="All Item Consumption" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ByItem
  },
  {
    path: '/reporting/consumption/bybatches',
    name: <I18nMessage id="Batches Consumption" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ByBatches
  },
  {
    path: '/reporting/consumption/consumptionstatistics',
    name: <I18nMessage id="Consumption Statistics" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ConsumptionStatistics
  },
  {
    path: '/reporting/production/byrecipe',
    name: <I18nMessage id="Dashboard" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ProductionByRecipe
  },
  {
    path: '/reporting/production/bytime',
    name: <I18nMessage id="Production By time" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ProductionByTime
  },
  {
    path: '/reporting/production/linedetails',
    name: <I18nMessage id="Line Details" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: LineDetails
  },
  {
    path: '/reporting/batch/batchsummary',
    name: <I18nMessage id="Batch Summary" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: BatchSummary
  },
  {
    path: '/reporting/batch/spcbatchgraph',
    name: <I18nMessage id="Batch Statistics Graph" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: batchStatisticsOrderWiseGraphRoute
  },
  {
    path: '/reporting/batch/statistics',
    name: <I18nMessage id="Batch Statistics" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: BatchStatistics
  },
  {
    path: '/reporting/batch/batchdetails/:id',
    name: <I18nMessage id="Batch Details" />,
    privileges: [],
    exact: true,
    role: ROLES.ALL_ROLES,
    component: BatchDetails
  },
  {
    path: [
      '/reporting/batch/batchsummary/:id/alarms',
      '/reporting/pcs/report/alarms'
    ],
    name: <I18nMessage id="Alarm Details" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: AlarmDetails
  },
  {
    path: '/reporting/diagnostics/realtime',
    name: <I18nMessage id="Realtime" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: Realtime
  },
  {
    path: '/reporting/diagnostics/outofspec',
    name: <I18nMessage id="OutOfSpec" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: OutOfSpec
  },
  {
    path: '/reporting/diagnostics/compare',
    name: <I18nMessage id="CompareReportparameters" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: CompareReportparameters
  },
  {
    path: '/reporting/diagnostics/view',
    name: <I18nMessage id="Diagnostics" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: DiagnosticsParameters
  },
  {
    path: '/reporting/diagnostics/downstream',
    name: <I18nMessage id="Downstream" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: DownstreamParameters
  },
  {
    path: '/reporting/pcs/report',
    name: <I18nMessage id="Report" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: pcsReport
  },
  {
    path: '/reporting/pcs/pressure',
    name: <I18nMessage id="Pressure" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: pcsPressure
  },
  {
    path: '/reporting/others/binHistory',
    name: <I18nMessage id="Bin History" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: BinHistory
  },
  {
    path:
      '/reporting/diagnostics/specificview/:lineId/:recipeId/:batchId/:productionScheduleId/:fromDate/:toDate',
    name: <I18nMessage id="Diagnostics" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: DiagnosticsParameters
  },
  {
    path: '/users',
    exact: true,
    name: <I18nMessage id="Users" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: Users
  },
  {
    path: '/users/createuser',
    name: <I18nMessage id="Create User" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: CreateUser
  },
  {
    path: '/users/edituser/:userId',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: EditUser
  },
  {
    path: '/copyparameter/:type',
    name: <I18nMessage id="copy parameters" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: CopyParameterRouter
  },
  {
    path: '/consumption/consumptionstatistics/spcgraph',
    name: <I18nMessage id="Consumption Statistic Graph" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: consumptionStatisticsOrderWiseGraphRoute
  },
  {
    path: '/import/:type',
    name: <I18nMessage id="import" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: importRoute
  },
  {
    path: '/reporting/others/stoptimereport',
    name: <I18nMessage id="Stop Time Report" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: StopTimeReport
  },
  {
    path: '/configurator/stoptime/stoptimegroup',
    exact: true,
    name: <I18nMessage id="Stop Time Group" />,
    privileges: [],
    role: ROLES.SUPERADMIN_ADMIN,
    component: StopTimeGroupRoutes
  },
  {
    path: '/configurator/stoptime/stoptimegroup/new',
    exact: true,
    name: <I18nMessage id="New" />,
    privileges: ['_LINE_CREATE'],
    role: ROLES.SUPERADMIN,
    component: AddStopTimeGroupRoutes
  },
  {
    path: '/configurator/stoptime/stoptimegroup/view/:id',
    exact: true,
    name: <I18nMessage id="View" />,
    privileges: ['_LINE_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: ViewStopTimeGroupRoutes
  },
  {
    path: '/configurator/stoptime/stoptimegroup/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: ['_LINE_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditStopTimeGroupRoutes
  },
  {
    path: '/configurator/item/itemclass',
    exact: true,
    name: <I18nMessage id="Item Class" />,
    privileges: ['CONFIGURATOR_READ'],
    role: ROLES.ALL_ROLES,
    component: ItemClassRoute
  },
  {
    path: '/configurator/item/itemclass/view/:id',
    exact: true,
    name: <I18nMessage id="View" />,
    privileges: ['CONFIGURATOR_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewItemClassRoutes
  },
  {
    path: '/configurator/item/itemclass/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: ['CONFIGURATOR_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditItemClassRoutes
  },
  {
    path: '/configurator/item/itemproperty',
    exact: true,
    name: <I18nMessage id="Item Property" />,
    privileges: 'CONFIGURATOR_READ',
    role: ROLES.ALL_ROLES,
    component: ItemPropertyRoute
  },
  {
    path: '/configurator/item/itemproperty/new',
    exact: true,
    name: <I18nMessage id="New" />,
    privileges: ['CONFIGURATOR_CREATE'],
    role: ROLES.SUPERADMIN,
    component: AddItemPropertyRoute
  },
  {
    path: '/configurator/item/itemproperty/view/:id',
    exact: true,
    name: <I18nMessage id="View" />,
    privileges: ['CONFIGURATOR_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewItemPropertyRoutes
  },
  {
    path: '/configurator/item/itemproperty/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: ['CONFIGURATOR_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditItemPropertyRoutes
  },
  {
    path: '/inventory-managment/carrier-type',
    exact: true,
    name: <I18nMessage id="Carrier Type" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: CarrierTypeRoute
  },
  {
    path: '/inventory-managment/carrier-type/new',
    exact: true,
    name: <I18nMessage id="New" />,
    privileges: ['INVENTORY_MANAGEMENT_CREATE'],
    role: ROLES.ALL_ROLES,
    component: AddCarrierTypeRoute
  },
  {
    path: '/inventory-managment/carrier-type/view/:id',
    exact: true,
    name: <I18nMessage id="View" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewCarrierTypeRoutes
  },
  {
    path: '/inventory-managment/carrier-type/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: ['INVENTORY_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditCarrierTypeRoutes
  },
  {
    path: '/inventory-managment/storage-area',
    exact: true,
    name: <I18nMessage id="Storage Area" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: StorageAreaRoute
  },
  {
    path: '/inventory-managment/storage-area/new',
    exact: true,
    name: <I18nMessage id="New" />,
    privileges: ['INVENTORY_MANAGEMENT_CREATE'],
    role: ROLES.ALL_ROLES,
    component: AddStorageAreaRoute
  },
  {
    path: '/inventory-managment/storage-area/view/:id',
    exact: true,
    name: <I18nMessage id="View" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewStorageAreaRoutes
  },
  {
    path: '/inventory-managment/storage-area/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: ['INVENTORY_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditStorageAreaRoutes
  },
  {
    path: '/inventorymanagment/carrier',
    exact: true,
    name: <I18nMessage id="Carrier" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: CarrierRoute
  },
  {
    path: '/inventorymanagment/carrier/new',
    exact: true,
    name: <I18nMessage id="New" />,
    privileges: ['INVENTORY_MANAGEMENT_CREATE'],
    role: ROLES.ALL_ROLES,
    component: AddCarrierRoute
  },
  {
    path: '/inventorymanagment/carrier/view/:id',
    exact: true,
    name: <I18nMessage id="View" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewCarrierRoutes
  },
  {
    path: '/inventorymanagment/carrier/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: ['INVENTORY_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditCarrierRoutes
  },
  {
    path: '/inventory-managment/mtu',
    exact: true,
    name: <I18nMessage id="Material Transfer Unit" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: MaterialTransferUnitRoute
  },
  {
    path: '/inventory-managment/mtu/new',
    exact: true,
    name: <I18nMessage id="New" />,
    privileges: ['INVENTORY_MANAGEMENT_CREATE'],
    role: ROLES.ALL_ROLES,
    component: AddMaterialTransferUnitRoute
  },
  {
    path: '/inventory-managment/mtu/view/:id',
    exact: true,
    name: <I18nMessage id="View" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewMaterialTransferUnitRoute
  },
  {
    path: '/inventory-managment/mtu/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: ['INVENTORY_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditMaterialTransferUnitRoute
  },
  {
    path: '/inventory-management/transfer-order',
    exact: true,
    name: <I18nMessage id="Transfer Order" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: TransferOrderRoute
  },
  {
    path: '/inventory-management/transfer-order/new',
    exact: true,
    name: <I18nMessage id="New" />,
    privileges: ['INVENTORY_MANAGEMENT_CREATE'],
    role: ROLES.ALL_ROLES,
    component: AddTransferOrderRoute
  },
  {
    path: '/inventory-management/transfer-order/view/:id',
    exact: true,
    name: <I18nMessage id="View" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewTransferOrderRoutes
  },
  {
    path: '/inventory-management/transfer-order/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: ['INVENTORY_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditTransferOrderRoutes
  },
  {
    path: '/reporting/traceability/lot-wise',
    exact: true,
    name: <I18nMessage id="Traceability" />,
    privileges: ['CONFIGURATOR_READ'],
    role: ROLES.ALL_ROLES,
    component: LotWiseTracebilityIndexRoute
  },
  {
    path: '/reporting/traceability/lot-wise/:lotId',
    exact: true,
    name: <I18nMessage id="Lot Wise" />,
    privileges: ['REPORTS_READ'],
    role: ROLES.ALL_ROLES,
    component: TraceabilityTrackingRoute
  },
  {
    path: '/inventory-management/inventory-correction/lot-correction',
    exact: true,
    name: <I18nMessage id="Lot Correction" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: GRNCorrectionRoute
  },
  {
    path: '/inventory-management/inventory-correction/lot-correction/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: ['INVENTORY_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditGRNCorrectionRoute
  },
  {
    path: '/inventory-management/inventory-correction/mtu-correction',
    exact: true,
    name: <I18nMessage id="MTU Correction" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: MTUCorrectionRoute
  },
  {
    path: '/inventory-management/inventory-correction/mtu-correction/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: ['INVENTORY_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditMTUCorrectionRoute
  },
  {
    path: '/reporting/inventory/lot-wise-inventory-report',
    exact: true,
    name: <I18nMessage id="Lot Wise Inventory Report" />,
    privileges: ['REPORTS_READ'],
    role: ROLES.ALL_ROLES,
    component: InventoryReport
  },
  {
    path: '/configurator/priority-selection',
    exact: true,
    name: <I18nMessage id="Priority Selection" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: PrioritySelection
  },
  {
    path: '/reporting/inventory/inventory-alert',
    exact: true,
    name: <I18nMessage id="Inventory Alert" />,
    privileges: ['REPORTS_READ'],
    role: ROLES.ALL_ROLES,
    component: InventoryAlert
  },
  {
    path: '/configurator/inventory/inventory-alert-subscriber',
    exact: true,
    name: <I18nMessage id="Inventory Alert Subscriber" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: InventoryAlertSubscriberRoute
  },
  {
    path: '/configurator/inventory/inventory-alert-subscriber/new',
    exact: true,
    name: <I18nMessage id="New" />,
    privileges: ['INVENTORY_MANAGEMENT_CREATE'],
    role: ROLES.ALL_ROLES,
    component: AddInventoryAlertSubscriberRoute
  },
  {
    path: '/configurator/inventory/inventory-alert-subscriber/view/:id',
    exact: true,
    name: <I18nMessage id="View" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewInventoryAlertSubscriberRoutes
  },
  {
    path: '/configurator/inventory/inventory-alert-subscriber/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: ['INVENTORY_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditInventoryAlertSubscriberRoutes
  },
  {
    path: '/general-settings',
    exact: true,
    name: <I18nMessage id="General Settings" />,
    privileges: [],
    role: ROLES.SUPERADMIN,
    component: GeneralSettings
  },
  {
    path: '/configurator/inventory/barcode-configuration',
    exact: true,
    name: <I18nMessage id="Barcode Configuration" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: BarcodeConfigurationRoute
  },
  {
    path: '/configurator/inventory/barcode-configuration/new/:type',
    exact: true,
    name: <I18nMessage id="New" />,
    privileges: ['INVENTORY_MANAGEMENT_CREATE'],
    role: ROLES.ALL_ROLES,
    component: AddBarcodeConfigurationRoute
  },
  {
    path: '/configurator/inventory/barcode-configuration/view/:id',
    exact: true,
    name: <I18nMessage id="View" />,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewBarcodeConfigurationRoutes
  },
  {
    path: '/configurator/inventory/barcode-configuration/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: ['INVENTORY_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditBarcodeConfigurationRoutes
  },
  {
    path: '/reporting/traceability/batch-wise',
    exact: true,
    name: <I18nMessage id="Batch Wise Traceability" />,
    privileges: ['CONFIGURATOR_READ'],
    role: ROLES.ALL_ROLES,
    component: BatchTraceabilityIndexRoute
  },
  {
    path: '/reporting/traceability/batch-wise/:batchId',
    exact: true,
    name: <I18nMessage id="Batch Wise" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: TraceabilityTrackingRoute
  },
  {
    path: '/reporting/inventory/storage-wise-inventory-report',
    exact: true,
    name: <I18nMessage id="Storage Wise Inventory Report" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: StorageWiseInventoryReport
  },
  {
    path: '/reporting/change-reports/engineering-parameters',
    exact: true,
    name: <I18nMessage id="Engineering Parameters Change Report" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: EngineeringParametersChangeReport
  },
  {
    path: '/reporting/change-reports/online-recipe',
    exact: true,
    name: <I18nMessage id="Online Recipe Change Report" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: OnlineRecipeChangeReport
  },
  {
    path: '/inventory-management/scrap-book',
    exact: true,
    name: <I18nMessage id="Scrap Booking" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ScrapBookRoute
  },
  {
    path: '/inventory-management/bin-lot',
    exact: true,
    name: <I18nMessage id="Bin Lot" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: BinLotRoute
  },
  {
    path: '/inventory-management/storage-locations',
    name: <I18nMessage id="Storage Locations" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: StorageLocations
  },
  {
    path: '/inventory-management/storage-locations/view/:id',
    name: <I18nMessage id="View" />,
    exact: true,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewStorageLocation
  },
  {
    name: <I18nMessage id="Edit" />,
    path: '/inventory-management/storage-locations/edit/:id',
    exact: true,
    privileges: ['INVENTORY_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditStorageLocation
  },
  {
    path: '/inventory-management/storage-locations/new',
    name: <I18nMessage id="New" />,
    exact: true,
    privileges: ['INVENTORY_MANAGEMENT_CREATE'],
    role: ROLES.ALL_ROLES,
    component: AddStorageLocation
  },
  {
    path: '/itemmanagement/vendors',
    name: <I18nMessage id="Vendors" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: Vendors
  },
  {
    path: '/itemmanagement/vendors/view/:id',
    name: <I18nMessage id="View" />,
    exact: true,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewVendor
  },
  {
    name: <I18nMessage id="Edit" />,
    path: '/itemmanagement/vendors/edit/:id',
    exact: true,
    privileges: ['INVENTORY_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditVendor
  },
  {
    path: '/itemmanagement/vendors/new',
    name: <I18nMessage id="New" />,
    exact: true,
    privileges: ['INVENTORY_MANAGEMENT_CREATE'],
    role: ROLES.ALL_ROLES,
    component: AddVendor
  },
  {
    path: '/inventory-management/grns',
    name: <I18nMessage id="GRN" />,
    exact: true,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: GRNRoute
  },
  {
    path: '/inventory-management/grns/view/:id',
    name: <I18nMessage id="View" />,
    exact: true,
    privileges: ['INVENTORY_MANAGEMENT_READ'],
    role: ROLES.ALL_ROLES,
    component: ViewGRNRoute
  },
  {
    name: <I18nMessage id="Edit" />,
    path: '/inventory-management/grns/edit/:id',
    exact: true,
    privileges: ['INVENTORY_MANAGEMENT_UPDATE'],
    role: ROLES.ALL_ROLES,
    component: EditGRNRoute
  },
  {
    path: '/inventory-management/grns/new',
    name: <I18nMessage id="New" />,
    exact: true,
    privileges: ['INVENTORY_MANAGEMENT_CREATE'],
    role: ROLES.ALL_ROLES,
    component: AddGRNRoute
  },
  {
    path: '/configurator/additionalparameters/mobile-parameters',
    exact: true,
    name: <I18nMessage id="Mobile Parameters" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: MobileParameterRoutes
  },
  {
    path: '/configurator/additionalparameters/mobile-parameters/new',
    exact: true,
    name: <I18nMessage id="New" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: AddMobileParameterRoutes
  },
  {
    path: '/configurator/additionalparameters/mobile-parameters/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: EditMobileParameterRoutes
  },
  {
    path: '/configurator/additionalparameters/mobile-parameters/view/:id',
    exact: true,
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: ViewMobileParameterRoutes
  },
  {
    path: '/configurator/limits',
    exact: true,
    name: <I18nMessage id="Limits" />,
    privileges: [],
    role: ROLES.SUPERADMIN,
    component: LimitRoute
  },
  {
    path: '/configurator/limits/edit/:id',
    exact: true,
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN,
    component: EditLimitRoute
  },
  {
    path: '/user-group',
    exact: true,
    name: <I18nMessage id="User Group" />,
    privileges: [],
    role: ROLES.SUPERADMIN,
    component: UserGroup
  },
  {
    path: '/user-group/create',
    name: <I18nMessage id="Create" />,
    privileges: [],
    role: ROLES.SUPERADMIN,
    component: CreateUserGroup
  },
  {
    path: '/user-group/edit/:id',
    name: <I18nMessage id="Edit" />,
    privileges: [],
    role: ROLES.SUPERADMIN,
    component: EditUserGroup
  },
  {
    path: '/user-group/view/:id',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.SUPERADMIN,
    component: ViewUserGroup
  },
  {
    path: '*',
    name: <I18nMessage id="View" />,
    privileges: [],
    role: ROLES.ALL_ROLES,
    component: PageNotFound
  }
];

export default routes;
