import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactTable from 'react-table';
import * as _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import I18nMessage from '../../components/i18n/I18nMessage';

class TableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyStyles: props.bodyStyles,
      tableStyles: props.tableStyles,
      showSrNoCol: props.showSrNoCol == undefined ? true : props.showSrNoCol,
      firstColWidth: props.firstColWidth,
      overrideSerialNumberColumn:
        props.overrideSerialNumberColumn == undefined
          ? false
          : props.overrideSerialNumberColumn
    };
    this.reference = props.ref || React.createRef();

    const { intl } = this.props;
    this.intl = intl;
  }

  componentDidMount = () => {
    this.element = ReactDOM.findDOMNode(this.reference.current);
    this.tableInit();
  };

  componentDidUpdate = () => {
    this.element = ReactDOM.findDOMNode(this.reference.current);
    this.tableInit();
  };

  tableInit = () => {
    const tableElem = this.element.getElementsByClassName('rt-table')[0];
    const bodyElem = this.element.getElementsByClassName('rt-tbody')[0];

    bodyElem &&
      bodyElem.addEventListener('wheel', e => {
        if (e.deltaX !== 0) {
          tableElem.scrollBy(e.deltaX, 0);
        }
      });

    if (this.reference.current.state.pageSize > 15) {
      //To not apply styles
      if (!_.isEmpty(this.state.bodyStyles)) {
        Object.keys(this.state.bodyStyles).map(key => {
          bodyElem.style[key] = this.state.bodyStyles[key];
        });
      }

      if (!_.isEmpty(this.state.tableStyles)) {
        Object.keys(this.state.tableStyles).map(key => {
          tableElem.style[key] = this.state.tableStyles[key];
        });
      }
    }
  };

  render() {
    let columns = this.props.columns;

    if (
      this.state.showSrNoCol &&
      !_.find(this.props.columns, { Header: 'Sr No' })
    ) {
      this.props.columns.unshift({
        Header: <I18nMessage id="Sr No" />,
        Cell: row => {
          return <div>{row.index + 1}</div>;
        }
      });
    }

    if (this.props.overrideSerialNumberColumn === false) {
      columns = this.props.columns.unshift({
        Header: <I18nMessage id="Sr No" />,
        Cell: row => {
          return <div>{row.index + 1}</div>;
        },
        width: this.state.firstColWidth
      });
    }

    return (
      <ReactTable
        ref={this.reference}
        columns={columns}
        previousText={this.intl.formatMessage({ id: 'Previous' })}
        nextText={this.intl.formatMessage({ id: 'Next' })}
        // loadingText={'Loading...'}
        noDataText={this.intl.formatMessage({ id: 'No rows found' })}
        pageText={this.intl.formatMessage({ id: 'Page' })}
        ofText={this.intl.formatMessage({ id: 'of' })}
        rowsText={this.intl.formatMessage({ id: 'rows' })}
        {...this.props}
      />
    );
  }
}

export default injectIntl(TableComponent);
