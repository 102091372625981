const WATCH_DOG_INFO_UPDATE = 'watchdog-info-update';

const initialState = {
  isConnected: false,
  dateTime: null
};

export default function reducer(state = initialState, action) {
  const newState = {};

  switch (action.type) {
    case WATCH_DOG_INFO_UPDATE:
      return Object.assign(newState, state, {
        isConnected: action.payload.isConnected ? action.payload.isConnected : false,
        dateTime: action.payload.dateTime ? action.payload.dateTime : null
      });

    default:
      return state;
  }
}

function initWatchInfo() {
  return {
    type: WATCH_DOG_INFO_UPDATE,
    payload: {}
  };
}

function watchDogInfoUpdate(info) {
  return {
    type: WATCH_DOG_INFO_UPDATE,
    payload: info
  };
}

export { initWatchInfo, watchDogInfoUpdate };
