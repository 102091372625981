import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from 'reactstrap';
import Collapse, { Panel } from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import TableComponent from '../../components/Table/Table';
import { withAlert } from 'react-alert';
import AuthService from '../../components/AuthService/authentication';
import I18nMessage from '../../components/i18n/I18nMessage';
import Loader from '../../components/UtilitiesService/Loader';
import config from '../../utils/config';
import { faqType, navigationConstants } from './faq_constant';
import { ListViewColumns } from './list_view_columns';

class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      faqs: [],

      // Logic
      info: false,
      tooltipOpen: false,
      loading: true,
      role: localStorage.getItem('role'),
      privileges: JSON.parse(localStorage.getItem('privileges'))
    };

    this.columns = new ListViewColumns(
      this.state.role,
      this.state.privileges,
      this.changeStatus,
      this.toggleInfo
    ).getColumns();

    this.toggle = this.toggle.bind(this);
    // this.toggleInfo = this.toggleInfo.bind(this);
    this.Auth = new AuthService();
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  toggleInfo = id => {
    this.setState({
      info: !this.state.info,
      deleteId: id ? id : ''
    });
  };

  // Handle delete based on the ID
  handleDelete(id) {
    this.setState({ loading: true });
    // Perform delete of particular id element and reload Data.
    this.Auth.deleteFaq(this.state.deleteId).then(res => {
      this.setState({ loading: false });
      if (res.success) {
        this.setState({
          info: !this.state.info,
          deleteId: ''
        });
        this.componentDidMount();
        this.props.alert.success(res.message);
      } else {
        this.setState({
          info: !this.state.info,
          deleteId: ''
        });
        this.props.alert.error(<I18nMessage id={res.message} />);
      }
    });
  }

  changeStatus = payload => {
    let body = { ...payload };
    body.active = !body.active;

    this.Auth.updateFaq(body._id, body)
      .then(res => {
        this.setState({ loading: false });
        if (res.success) {
          payload.active = !payload.active;
          this.props.alert.success(res.message);
        } else {
          this.props.alert.error(<I18nMessage id={res.message} />);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.alert.error(<I18nMessage id="Error Updating FAQ" />);
      });
  };

  componentDidMount() {
    this.setState({
      role: localStorage.getItem('role'),
      privileges: JSON.parse(localStorage.getItem('privileges'))
    });

    let params = {};

    if (localStorage.getItem('role') !== '_SUPER') {
      params.active = true;
      params.type = faqType.HELP;
    }

    // Get all FAQ's listed.
    this.Auth.getAllFaqs(params)
      .then(res => {
        this.setState({ loading: false });
        if (res.success) {
          this.setState({
            faqs: res.result,

            // Loading false.
            loading: false
          });
        } else {
          this.props.alert.error(<I18nMessage id="Error Getting FAQ's" />);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.alert.error(<I18nMessage id="Error Getting FAQ's" />);
      });
  }

  click = id => {
    this.props.history.push(`${navigationConstants.HELP_VIEW_PATH}${id}`);
  };
  render() {
    let data = this.state.faqs;

    const columns = [...this.columns];

    if (this.state.search) {
      data = data.filter(row => {
        return (
          row.materialCategoryName.includes(this.state.search) ||
          row.materialCategoryType.includes(this.state.search)
        );
      });
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" lg="12">
            <Card>
              <CardHeader>
                <strong>
                  <I18nMessage id="Help" />
                </strong>
                {this.state.role === '_SUPER' ? (
                  <div className="card-header-actions">
                    <Col col="6" sm="4" md="2" xl className="mb-3 mb-xl-0">
                      <Link to={navigationConstants.HELP_ADD_NEW}>
                        <Button
                          block
                          outline
                          color="primary"
                          aria-pressed="true"
                        >
                          <I18nMessage id="Add New" />
                        </Button>
                      </Link>
                    </Col>
                  </div>
                ) : null}
              </CardHeader>
              <CardBody>
                {this.state.loading ? (
                  <Loader loading={this.state.loading} />
                ) : this.state.role === '_SUPER' ? (
                  <TableComponent
                    data={data}
                    columns={columns}
                    sortable={true}
                    loading={this.state.loading}
                    showPaginationBottom={config.showPaginationBottom}
                    pageSize={
                      data.length <= 1
                        ? 5
                        : data.length < 50
                        ? data.length
                        : config.pageSize
                    }
                    showPageSizeOptions={false}
                    className="-striped -highlight"
                    showPageJump={false}
                    bodyStyles={{
                      'overflow-x': 'hidden',
                      'overflow-y': 'scroll',
                      height: '550px'
                    }}
                    tableStyles={{}}
                  />
                ) : (
                  this.state.faqs.map((Obj, idx) => {
                    if (Obj.type === faqType.FAQ) {
                      return (
                        <Collapse accordion={false}>
                          <Panel
                            header={parseInt(idx) + 1 + '.' + Obj.question}
                            style={{ fontWeight: 700, fontSize: '16px' }}
                          >
                            <p
                              style={{ fontWeight: 'normal', fontSize: '14px' }}
                            >
                              {Obj.answer}
                            </p>
                          </Panel>
                        </Collapse>
                      );
                    } else {
                      return (
                        <Collapse
                          accordion={false}
                          onChange={this.click.bind(this, Obj._id)}
                        >
                          <Panel
                            header={parseInt(idx) + 1 + '.' + Obj.title}
                            style={{ fontWeight: 700, fontSize: '16px' }}
                          />
                        </Collapse>
                      );
                    }
                  })
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={this.state.info}
          toggle={this.toggleInfo}
          className={'modal-info ' + this.props.className}
        >
          <ModalHeader toggle={this.toggleInfo}>
            <I18nMessage id="Alert" />
          </ModalHeader>
          <ModalBody>
            <I18nMessage id="Are you sure that you want to delete the selected item" />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleDelete.bind(this)}>
              <I18nMessage id="Yes" />
            </Button>{' '}
            <Button color="secondary" onClick={this.toggleInfo}>
              <I18nMessage id="No" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withAlert(withRouter(FAQ));
