import React, { Component } from 'react';
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Row,
  Card,
  CardBody,
  CardFooter,
  Badge
} from 'reactstrap';
import AuthService from '../AuthService/authentication';
import { withAlert } from 'react-alert';
import FormValidator from '../UtilitiesService/FormValidator';
import zxcvbn from 'zxcvbn';
import I18nMessage from '../i18n/I18nMessage';
import localStorage from 'localStorage';
import Loader from '../UtilitiesService/Loader';

class ResetOnPrimiseUserPassword extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: <I18nMessage id="Please Enter your password" />
      },
      {
        field: 'password',
        method: function passwordStrength(field, args) {
          if (field.length < 8) {
            return false;
          } else {
            return true;
          }
        },
        validWhen: true,
        message: <I18nMessage id="Password must be atleast of 8 letters" />
      },
      {
        field: 'password',
        method: function passwordStrength(field, args) {
          var strength = zxcvbn(field);
          if (strength.score > 2) {
            return true;
          } else {
            return false;
          }
        },
        validWhen: true,
        message: <I18nMessage id="Password Weak" />
      },
      {
        field: 'confirmPassword',
        method: 'isEmpty',
        validWhen: false,
        message: <I18nMessage id="Please Enter your password again" />
      },
      {
        field: 'confirmPassword',
        method: function isEqualToPassword(field, args) {
          if (field === args.password) {
            return true;
          } else {
            return false;
          }
        },
        validWhen: true,
        message: <I18nMessage id="Passwords do not match" />
      }
    ]);

    this.state = {
      password: '',
      confirmPassword: '',
      validation: this.validator.initialize(),
      result: false,
      loading: false,
      email: this.props.match.params.email ? this.props.match.params.email : '',
      passwordStrength: { text: '', score: 0, color: '' }
    };
    this.Auth = new AuthService();
  }

  componentDidMount() {
    // Get token from params and verify.
    // this.verifyResetPassword(this.props.match.params.token);
    this.setState({ validation: this.validator.initialize() });
  }

  render() {
    console.log(this.props);
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <h1>
                    <I18nMessage id="Change Password" />
                  </h1>
                  {this.state.passwordStrength.score > -1 ? (
                    <Badge
                      className="float-right mb-1"
                      color={this.state.passwordStrength.color}
                    >
                      {this.state.passwordStrength.text}
                    </Badge>
                  ) : null}
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="oldPassword"
                      placeholder="Old Password"
                      value={this.state.email}
                      disabled={true}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      valid={this.state.oldPassword ? true : false}
                      invalid={this.state.oldPassword ? false : true}
                      type="password"
                      name="oldPassword"
                      placeholder="Old Password"
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.state.validation.password.isInvalid &&
                    this.state.validation.password.isTouched ? (
                      <FormFeedback invalid>
                        Please enter your old password
                      </FormFeedback>
                    ) : (
                      <FormFeedback valid>
                        {this.state.validation.password.message}
                      </FormFeedback>
                    )}
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      valid={this.state.newPassword ? true : false}
                      invalid={this.state.newPassword ? false : true}
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.state.validation.password.isInvalid &&
                    this.state.validation.password.isTouched ? (
                      <FormFeedback invalid>
                        Please enter your new password
                      </FormFeedback>
                    ) : (
                      <FormFeedback valid>
                        {this.state.validation.password.message}
                      </FormFeedback>
                    )}
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      valid={
                        this.state.confirmPassword === this.state.newPassword
                          ? true
                          : false
                      }
                      invalid={
                        this.state.confirmPassword === this.state.newPassword
                          ? false
                          : true
                      }
                      type="password"
                      name="confirmPassword"
                      placeholder="Re-enter New Password"
                      onChange={this.handleChange.bind(this)}
                    />
                    {this.state.validation.confirmPassword.isInvalid &&
                    this.state.validation.confirmPassword.isTouched ? (
                      <FormFeedback invalid>
                        Please confirm your new password
                      </FormFeedback>
                    ) : (
                      <FormFeedback valid>
                        {this.state.validation.confirmPassword.message}
                      </FormFeedback>
                    )}
                  </InputGroup>
                  <Button
                    color="success"
                    onClick={this.handleResetPassword.bind(this)}
                    block
                    disabled={
                      this.state.oldPassword &&
                      this.state.newPassword &&
                      this.state.confirmPassword
                        ? false
                        : true
                    }
                  >
                    <I18nMessage id="Change Password" />
                  </Button>
                  <Loader loading={this.state.loading} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      function() {
        const validation = this.validator.validate(this.state);
        this.setState({ validation: validation });
      }
    );

    // Password Strength indicator.
    if (e.target.name === 'password') {
      let passwordStrengthField = {};
      passwordStrengthField.score = zxcvbn(e.target.value).score;
      switch (passwordStrengthField.score) {
        case 0:
        case 1:
        case 2:
          passwordStrengthField.color = 'danger';
          passwordStrengthField.text = <I18nMessage id="Weak" />;
          break;
        case 3:
          passwordStrengthField.color = 'warning';
          passwordStrengthField.text = <I18nMessage id="Medium" />;
          break;
        case 4:
        case 5:
          passwordStrengthField.color = 'success';
          passwordStrengthField.text = <I18nMessage id="Strong" />;
          break;
        default:
          break;
      }

      this.setState({
        passwordStrength: passwordStrengthField
      });
    }
  }

  // Route to login.
  routeToLogin(e) {
    e.preventDefault();
    this.props.history.push('/login');
  }

  // Handle Change Password.
  handleResetPassword(e) {
    e.preventDefault();
    const validation = this.validator.validate(this.state);

    let paramGroup = {
      email: this.state.email,
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword
    };
    this.setState({ loading: true });
    this.Auth.resetOnPremiseUserPassword(paramGroup)
      .then(res => {
        this.setState({ loading: false });
        if (res.success) {
          localStorage.removeItem('id_token');
          localStorage.removeItem('systemType');
          localStorage.removeItem('role');
          localStorage.removeItem('privileges');
          this.props.alert.success(res.message);
          this.props.history.push('/login');
        } else {
          this.props.alert.error(res.message);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.alert.error(<I18nMessage id="Error Unknown" />);
      });
  }
}

export default withAlert(ResetOnPrimiseUserPassword);
