import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import { socket } from '../../components/UtilitiesService/socket';
import { watchDogConstants } from '../../utils/constantstrings';
import { initWatchInfo, watchDogInfoUpdate } from './watch_dog_reducer';
import './watch_dog.css';
import * as Unicons from '@iconscout/react-unicons';

class WatchDogConnection extends React.Component {
  componentDidMount() {
    // Connect to websocket with watchdog channel
    socket.on(watchDogConstants.WATCH_DOG_CHANNEL, data => {
      if (data) {
        this.props.dispatch(
          watchDogInfoUpdate({
            isConnected: data.isConnected,
            dateTime: data.dateTime
          })
        );
      }
    });
  }

  componentWillUnmount() {
    // Reset watchdog info on the unmount
    this.props.dispatch(initWatchInfo());
    socket.removeAllListeners(watchDogConstants.WATCH_DOG_CHANNEL);
  }

  getTimeDate = (date, currentDate = new Date()) => {
    const timeDifference = (currentDate - new Date(date)) / 1000; // Convert to seconds
  
    const intervals = {
      day: 86400,
      hour: 3600,
      minute: 60,
    };
  
    for (const [unit, secondsInUnit] of Object.entries(intervals)) {
      const difference = Math.floor(timeDifference / secondsInUnit);
      console.log("differenced",difference);
      if (difference > 0) {
        return difference === 1
          ? `1 ${unit} ago`
          : `${difference} ${unit}s ago`;
      }
    }
    return 'Just now';
  };
  

  render() {
    let Icon=( this.props.watchDog && this.props.watchDog.isConnected) ? <Unicons.UilCloudCheck className="icon-spacing"/> : <Unicons.UilCloudSlash className="icon-spacing"/>;
    let status = ( this.props.watchDog && this.props.watchDog.isConnected) ? `Connected ` : `Disconnected`;
    let spanClassName = ( this.props.watchDog && this.props.watchDog.isConnected) ? "watchdog-connection-success" :"watchdog-connection-warning"
    return (
      <span className={spanClassName}>
        {Icon}
        <FormattedMessage id="app.watch_dog.connection_stopped" defaultMessage={`${status} ${this.getTimeDate(this.props.watchDog.dateTime)}`} className="icon-spacing"/>
      </span>
    );
    
  }
}

const mapStateToProps = state => {
  return { watchDog: state.WatchDogReducer };
};

export default withAlert(connect(mapStateToProps)(WatchDogConnection));
