import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SOFTWARE_PROVIDER } from '../Constants/GeneralConstants';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class HomeFooter extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <span>
          &copy; {moment().format('YYYY')} {SOFTWARE_PROVIDER.COMPANY_NAME}
        </span>
        <span className="ml-auto">
          Powered by{' '}
          <a href={SOFTWARE_PROVIDER.COMPANY_WEBSITE}>
            {SOFTWARE_PROVIDER.COMPANY_NAME}
          </a>
        </span>
      </React.Fragment>
    );
  }
}

HomeFooter.propTypes = propTypes;
HomeFooter.defaultProps = defaultProps;

export default HomeFooter;
