import React, { Component } from 'react';
import { Nav, NavItem, Button } from 'reactstrap';
import PropTypes from 'prop-types';

import { AppNavbarBrand } from '@coreui/react';
import logo from '../../assets/img/brand/NAMiQ_Logo.jpg';
import { withAlert } from 'react-alert';
import AuthService from '../AuthService/authentication';
import { SOFTWARE_PROVIDER } from '../Constants/GeneralConstants';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class HomeHeader extends Component {
  constructor() {
    super();

    this.Auth = new AuthService();
    this.checkSuperLogin = this.checkSuperLogin.bind(this);
  }

  static contextTypes = {
    router: PropTypes.object
  };

  checkSuperLogin(e) {
    e.preventDefault();
    this.Auth.checkSuperUserLoginRequired()
      .then(res => {
        // Examine the text in the response
        if (res.success) {
          if (res.superUserLogin) {
            // Redirect to super user login.
            this.context.router.history.push('/superuserlogin');
          } else {
            // Redirect to normal login.
            this.context.router.history.push('/login');
          }
        } else {
          if (res.errors) {
            this.props.alert.error(res.errors[Object.keys(res.errors)[0]]);
          } else {
            this.props.alert.error(res.message);
          }
        }
      })
      .catch(err => {
        this.props.alert.error(
          'Something went wrong. Please contact administrator.'
        );
      });
  }

  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppNavbarBrand
          full={{
            src: logo,
            width: 140,
            height: 19,
            alt: `${SOFTWARE_PROVIDER.LOGO_ALT_TEXT}`
          }}
        />

        <Nav className="ml-auto" navbar>
          <NavItem className="px-3 d-none d-sm-block d-sm-none d-md-block">
            <Button
              color="link"
              className="px-0"
              onClick={this.checkSuperLogin.bind(this)}
            >
              Login
            </Button>
          </NavItem>
        </Nav>
        <Nav
          className="ml-auto d-none d-sm-block d-md-none d-block d-sm-none"
          navbar
        >
          <NavItem className="px-3">
            <Button
              color="link"
              className="px-0"
              onClick={this.checkSuperLogin.bind(this)}
            >
              Login
            </Button>
          </NavItem>
        </Nav>
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

HomeHeader.propTypes = propTypes;
HomeHeader.defaultProps = defaultProps;

export default withAlert(HomeHeader);
