import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { SOFTWARE_PROVIDER } from '../Constants/GeneralConstants';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class ProtectedFooter extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <span>
          <b>(c) {moment().format('YYYY')} {SOFTWARE_PROVIDER.PRODUCT_NAME}</b>
        </span>
        <span className="ml-auto">
          <b>
            <FormattedMessage
              id="app.footer.powered_by"
              defaultMessage="Powered by"
            />{' '}
            <a href={SOFTWARE_PROVIDER.COMPANY_WEBSITE}>
              {SOFTWARE_PROVIDER.COMPANY_NAME}
            </a>
          </b>
        </span>
      </React.Fragment>
    );
  }
}

ProtectedFooter.propTypes = propTypes;
ProtectedFooter.defaultProps = defaultProps;

export default ProtectedFooter;
