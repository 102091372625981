import { createStore, combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import ParamStateStoreReducer from '../src/reduxHelper/reducer';
import WatchDogReducer from './views/watch_dog/watch_dog_reducer';
import deepExtend from 'deep-extend';

const UPDATE_LOCALES = 'UPDATE_LOCALES';

function localesReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_LOCALES:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

const reducer = combineReducers({
  intl: intlReducer,
  locales: localesReducer,
  ParamStateStoreReducer: ParamStateStoreReducer,
  WatchDogReducer: WatchDogReducer
});

const rootReducer = (state, action) => {
  return reducer(state, action);
};

const persistedState = localStorage.getItem("namiq") ? JSON.parse(localStorage.getItem("namiq")) : {};
const initialState = deepExtend(createStore(rootReducer).getState(), persistedState);
const store = createStore(rootReducer, initialState);

/* Subscribe to redux data object change event */
store.subscribe(() => {
  localStorage.setItem("namiq", JSON.stringify(store.getState()));
});

export default store;
