export const faqType = {
  FAQ: 'FAQ',
  HELP: 'HELP'
};

export const navigationConstants = {
  HELP_INDEX_PATH: '/help',
  HELP_VIEW_PATH: '/help/view/',
  HELP_EDIT_PATH: '/help/edit/',
  HELP_ADD_NEW: '/help/new'
};
