const UrlFormatter = (url, query) => {
  // iterate over the query.
  if (query) {
    url +=
      (url.indexOf('?') === -1 ? '?' : '&') +
      Object.keys(query)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(query[k]))
        .join('&');
  }

  // return URL
  return url;
};

export default UrlFormatter;
