import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler
} from '@coreui/react';
import logo from '../../assets/img/brand/NAMiQ_Logo.jpg';
import logoRound from '../../assets/img/brand/NAMIQ_round_logo.png';
import AuthService from '../AuthService/authentication';
import I18nMessage from '../i18n/I18nMessage';
import localStorage from 'localStorage';
import _ from 'lodash';
import { SOFTWARE_PROVIDER } from '../Constants/GeneralConstants';

import {
  CONFIG_UI_LOCAL_STORAGE,
  CONFIG_UI_KEY
} from '../../utils/constantstrings';
import WatchDogConnection from '../../views/watch_dog/watch_dog_connection';

// Redux and Localization
import SwitchLocale from '../SwitchLocale';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class ProtectedHeader extends Component {
  constructor() {
    super();
    this.state = {
      dropdownOpen: [false, false],
      userEmail: '',
      role: ''
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.Auth = new AuthService();
    this.checkSuperLogin = this.checkSuperLogin.bind(this);
    this.handleResetpassword = this.handleResetpassword.bind(this);
  }

  componentDidMount() {
    const userObject = this.Auth.getProfile();
    this.setState({
      userEmail: userObject.userEmail,
      role: userObject.role
    });
  }

  checkSuperLogin() {
    this.Auth.checkSuperUserLoginRequired()
      .then(res => {
        // Examine the text in the response
        if (res.success) {
          if (res.superUserLogin) {
            // Redirect to super user login.
            this.props.history.push('/superuserlogin');
          } else {
            // Redirect to normal login.
            this.props.history.push('/login');
          }
        } else {
          if (res.errors) {
            this.props.alert.error(res.errors[Object.keys(res.errors)[0]]);
          } else {
            this.props.alert.error(res.message);
          }
        }
      })
      .catch(err => {
        this.props.alert.error(
          'Something went wrong. Please contact administrator.'
        );
      });
  }

  toggle(i) {
    const newArray = this.state.dropdownOpen.map((element, index) => {
      return index === i ? !element : false;
    });
    this.setState({
      dropdownOpen: newArray
    });
  }

  handleLogout(e) {
    e.preventDefault();
    this.Auth.logout().then(() => {
      localStorage.removeItem('systemType');
      localStorage.removeItem('role');
      localStorage.removeItem('privileges');
      localStorage.removeItem('siteType');
      localStorage.removeItem('disabledNavListUrl');
      localStorage.removeItem('recipeCreationMethod');
      this.checkSuperLogin();
    });
  }

  handleResetpassword(e) {
    e.preventDefault();
    this.props.history.push(
      '/reset-on-premise-user-password/' + this.state.userEmail
    );
  }

  handleRefreshConfigUI(e) {
    e.preventDefault();

    this.getAllLines();
  }

  getAllLines() {
    // Get all lines listed.
    this.Auth.lines()
      .then(res => {
        this.setState({ loading: false });
        if (res.success) {
          let lines = res.lines;
          let linesWithConfigUI = _.map(
            _.filter(lines, Obj => {
              return !_.isEmpty(Obj[CONFIG_UI_KEY]);
            }),
            Obj => _.pick(Obj, '_id', CONFIG_UI_KEY)
          );

          localStorage.setItem(
            CONFIG_UI_LOCAL_STORAGE,
            JSON.stringify(linesWithConfigUI)
          );
        }
        // Else Show Error
        else {
          this.props.alert.error(res.message);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.alert.error(<I18nMessage id="Error Getting Lines" />);
      });
  }

  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{
            src: logo,
            width: 140,
            height: 19,
            alt: `${SOFTWARE_PROVIDER.LOGO_ALT_TEXT}`
          }}
          minimized={{
            src: logoRound,
            width: 30,
            height: 30,
            alt: 'Smartmix Logo'
          }} />
        <WatchDogConnection />
        <Nav navbar>
          <SwitchLocale />
          <ControlSwitch />
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <img
                src={require('../../assets/img/avatars/userAvatar.png')}
                className="img-avatar"
                alt="User"
              />
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem header>{this.state.userEmail}</DropdownItem>
              {this.state.role === '_SUPER' ? (
                <DropdownItem onClick={this.handleRefreshConfigUI.bind(this)}>
                  <i className="fa fa-refresh" aria-hidden="true" />{' '}
                  <I18nMessage id="Refresh Config UI" />
                </DropdownItem>
              ) : (
                <DropdownItem onClick={this.handleResetpassword.bind(this)}>
                  <i className="icon-lock" />{' '}
                  <I18nMessage id="Change Password" />
                </DropdownItem>
              )}

              <Link to={`/help`}>
                <DropdownItem>
                  <i className="fa fa-question-circle" aria-hidden="true" />{' '}
                  <I18nMessage id="Help" />
                </DropdownItem>
              </Link>

              <DropdownItem onClick={this.handleLogout.bind(this)}>
                <i className="fa fa-sign-out" /> <I18nMessage id="Logout" />
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

const ControlSwitch = () => {
  /**
   * openControlApp functions opens a child process of nodejs and executes
   * a script places in the mentioned folder below.
   *
   */
  const openControlApp = () => {
    const process = window.require('child_process'); // The power of Node.JS
    const cmd = 'C:\\NAMIQ\\toggle_tia.vbs'; // TODO: Hard coded for now should be moved to config
    process.spawn('cscript.exe', [cmd]);
  };

  return (
    <div className="control-switch-panel" onClick={openControlApp}>
      <img
        src={require('../../assets/img/switch.svg')}
        className="side-nav-icon"
        alt="switch-icon"
      />
      <span className="to-control-text">
        <FormattedMessage
          id="sidebar.switch_tab_text"
          defaultMessage="To Control"
        />
      </span>
    </div>
  );
};

ProtectedHeader.propTypes = propTypes;
ProtectedHeader.defaultProps = defaultProps;

export default withAlert(withRouter(ProtectedHeader));
