import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
// Styles
// CoreUI Icons Set
// import '@coreui/icons/css/coreui-icons.min.css';
import '@coreui/coreui/dist/css/coreui.min.css'
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import 'react-table/react-table.css';
import './scss/style.css';
import './index.css';

import { addLocaleData } from 'react-intl';
import { IntlProvider } from 'react-intl-redux';
import { Provider } from 'react-redux';
import store from './store';

import AuthService from './components/AuthService/authentication';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from './components/Alerts';
import registerServiceWorker from './registerServiceWorker';
import { updateIntl } from 'react-intl-redux';

import deLocaleData from 'react-intl/locale-data/de';
import enLocaleData from 'react-intl/locale-data/en';

import moment from 'moment';

// Add import of locales supported by our application
import 'moment/locale/de'; // deautsch
// import 'moment/locale/hi';  // hindi
// import 'moment/locale/ja';  // japanese

addLocaleData([...deLocaleData, ...enLocaleData]);

const UPDATE_LOCALES = 'UPDATE_LOCALES';

// Alert provider style cofiguration
const options = {
  position: 'top center',
  timeout: 3000,
  offset: '30px',
  transition: 'scale',
  zIndex: 999999
};

// Handle All Locales
function handleLoadlLocales() {
  fetch(
    `${process.env.REACT_APP_SERVER_URL}/auth/translation/getclientlocale`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
    .then(response => response.json())
    .then(res => {
      let formattedResult = {};

      // // Format Message

      if (res.success) {
        let allLanguages = res.data;
        for (let i = 0; i < allLanguages.length; i++) {
          formattedResult[allLanguages[i].languageCode] =
            res.data[i].translations;
        }
        // Redirect to server URL
      } else {
        // Redirect to Local Json File.
      }

      let translations = formattedResult;

      store.dispatch({
        type: UPDATE_LOCALES,
        payload: translations
      });

      // Update Locale to English by default.
      let loadedLocale = store.getState();

      // Check localstorage for any existing locale
      let locale = JSON.parse(localStorage.getItem('locale'));
      let defaultLocale = locale || 'en';
      let defalultLocales = {};
      if (loadedLocale.locales.intl) {
        defaultLocale = loadedLocale.intl.locale;
      }

      if (loadedLocale.locales) {
        defalultLocales = loadedLocale.locales;
      }

      moment.locale(defaultLocale);
      store.dispatch(
        updateIntl({
          locale: defaultLocale,
          messages: defalultLocales[defaultLocale]
        })
      );
    })
    .then(() => {
      ReactDOM.render(
        <Provider store={store}>
          <IntlProvider>
            <AlertProvider template={AlertTemplate} {...options}>
              <AuthService />
            </AlertProvider>
          </IntlProvider>
        </Provider>,
        document.getElementById('root')
      );
    });
}

handleLoadlLocales();

registerServiceWorker();
