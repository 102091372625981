export const statusCodes = {
  SUCCESSFULLY_RECEIVED: 1,
  SUCCESSFULLY_SAVED: 2,
  SUCCESSFULLY_UPDATED: 3,
  SUCCESSFULLY_DELETED: 4,
  SUCCESSFULLY_STATUS_UPDATE: 5,
  SUCCESSFULLY_AUTHENTICATED: 6,
  SUCCESSFULLY_LOGGED_OUT: 7,
  SUCCESSFULLY_IMPORTED: 8,
  LIMIT_EXCEEDED: 9,
  SUCCESSFULLY_SYNCED: 10,
  ERROR: -1,
  OPERATION_FAILED: -1
};

// Common Strings and Hearer strings
export const BackendConstants = {
  LOCALE: 'locale',
  CONTENT_TYPE_JSON: 'application/json',
  CONTENT_TYPE_SVG: 'image/svg+xml',
  CONTENT_TYPE_XLSX:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CONTENT_TYPE_CSV: 'text/csv; charset=UTF-8',
  AUTHORIZATION_STRING: 'Authorization',
  BEARER_STRING: 'bearer ', // Space required do not delete space
  TOKEN_STRING: 'id_token',
  CONTENT_TYPE_XML: 'text/xml',
  CONTENT_TYPE_TEXT: 'text/plain'
};

// REst API methods Constants
export const method = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
  PATCH: 'PATCH'
};

export const validFloatKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '-'
];

//Operation codes
export const opCodes = {
  NEW: 1,
  EDIT: 2,
  COPY: 3,
  DELETE: 4,
  VIEW: 5,
  DROPDOWN: 6,
  AUTHENTICATE: 7,
  LOGOUT: 8,
  SYNC_TO_PLC: 9
};

export const DATATYPES = ['INTEGER', 'STRING', 'BOOLEAN', 'REAL'];

export const DOMAIN =
  process.env.REACT_APP_SERVER_URL || 'http://localhost:3000';
export const STATUSCODES = {
  SUCCESS: 1,
  ERROR: -1,
  HTTP_RESPONSE_INTERNAL_SERVER_ERROR: 500,
  HTTP_RESPONSE_UNAUTHORIZED: 401,
  DOCUMENT_EXISTS: 201,
  VALIDATION_ERROR: 400,
  NOTFOUND: 404
};

// const DOMAIN = process.env.REACT_APP_SERVER_URL || 'http://localhost:3000';

// module.exports = {
//   STATUSCODES
//   // DOMAIN
// };

export const CALENDER_TIME_FORMAT = 'DD/MM/YYYY h:mm a';

export const LIST_VIEW_TIME_FORMAT = 'MMM Do YY, h:mm:ss A';

export const AUTO_GENERATED_ID_COLUMN_WIDTH = 120;

export const configuratorStatus = {
  DELETE_IN_PROGRESS: 'DELETE_IN_PROGRESS'
};

export const UDT_NAME = {
  LINE: 'LINE',
  ACTION: 'ACTION',
  ACTION_PARAMETER: 'ACTION_PARAMETER',
  ITEM: 'ITEM',
  VESSEL: 'VESSEL',
  BIN_ASSIGNMENT: 'BIN_ASSIGNMENT',
  ACTION_TO_VESSEL_ASSIGNMENT : 'ACTION_TO_VESSEL_ASSIGNMENT',
  LINE_TO_VESSEL_ASSIGNMENT: 'LINE_TO_VESSEL_ASSIGNMENT'
};

export const ACTION_TYPES = {
  Others: 'Others',
  Initiate: 'Initiate',
  iRam: 'Iram',
  RAM: 'RAM',
  Discharge: 'Discharge',
  feed: 'Feed',
  MultipleFeed: 'MultipleFeed',
  Auto: 'Auto',
  Hand: 'Hand',
  Mixing: 'Mixing',
  Waiting: 'Waiting',
  Weighing: 'Weighing',
  FM800: 'FM800'
};

export const status_codes = {
  SUCCESSFULLY_RECEIVED: 1,
  ERROR: -1
};

export const PRODUCT_TYPE = {
  TIRE: 'TIRE',
  FOOD: 'FOOD'
};

export const batchStatus = {
  IN_ACTIVATED: {
    code: 100,
    text: 'Inactive'
  },
  ABORTED: {
    code: 101,
    text: 'Aborted'
  },
  ACTIVATED: {
    code: 200,
    text: 'Active'
  },
  READY: {
    code: 300,
    text: 'Ready'
  },
  RUNNING: {
    code: 400,
    text: 'Running'
  },
  FINISHED: {
    code: 500,
    text: 'Finished'
  }
};

export const scheduleStatus = {
  IN_ACTIVATED: {
    code: 'INACTIVE',
    text: 'Inactive'
  },
  ABORTED: {
    code: 'CANCELLED',
    text: 'Aborted'
  },
  ACTIVATED: {
    code: 'WAITING',
    text: 'Active'
  },
  READY: {
    code: 'WAITING',
    text: 'Activated'
  },
  READY: {
    code: 'READY',
    text: 'Ready'
  },
  RUNNING: {
    code: 'PROCESSING',
    text: 'Running'
  },
  FINISHED: {
    code: 'COMPLETE',
    text: 'Finished'
  }
};

export const CodeText = {
  'INACTIVE': 'Inactive',
  'CANCELLED': 'Aborted',
  'WAITING': 'Activated',
  'READY': 'Ready',
  'PROCESSING': 'Running',
  'COMPLETE': 'Finished'
};

export const ARROW_DIRECTION = {
  UP: 'UP',
  DOWN: 'DOWN'
};

export const recipeCreationMethod = {
  withBom: 'WITH_BOM',
  WithoutBom: 'WITHOUT_BOM'
};

export const SOFTWARE_PROVIDER = {
  PRODUCT_NAME: 'NAMiQ',
  COMPANY_NAME: 'Zeppelin Systems GmbH',
  LOGO_ALT_TEXT: '',
  COMPANY_WEBSITE: 'https://www.zeppelin.com/'
};

export const SYNC_TO_PLC_TYPE = {
  LINE_TO_VESSEL_ASSIGNMENT: 'LINE_TO_VESSEL_ASSIGNMENT',
  ACTION_TO_VESSEL_ASSIGNMENT : 'ACTION_TO_VESSEL_ASSIGNMENT'
};