//Reason for creating a custom Toolkit is because we dont have any way to create tooltip except for UncontrolledTooltip which is compatible with react and not custom/compiled/derived elems.

//We are using @popperjs/core which is a dependency for UncontrolledTooltip which is used in reactstrap

export default class Tooltip {
  constructor(props) {
    this.targetElem = props.target;
    this.content = props.content;
  }

  initialize = () => {
    if (this.targetElem) {
      this.targetElem.setAttribute('title', this.content);
    }
  };
}
