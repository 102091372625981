/**
 * Socket channel names
 */
export const GET_LINE_STATUS_SOCKET = 'getLineStatusSocket';
export const GET_LINE_STATUS_RESPONSE_SOCKET = 'lineStatusResponseSocket';
export const GET_BATCH_DIAGNOSTICS_SOCKET = 'diagnostics';
export const ERROR_SOCKET = 'errorResponseSocket';

//Local Storage Keys
export const CONFIG_UI_LOCAL_STORAGE = 'configUI';

//ConfigUI Key set in lines model
export const CONFIG_UI_KEY = 'configUI';

//ConfigUI Paths
export const ConfigUI_Paths = {
  recipeWeighing: 'configUI.recipeConfig.weighing',
  iRam: 'configUI.iramConfig',
  schedulingUI: 'configUI.schedulingUI'
};

//IRam keys
export const PRESSURE_IRAM = 'PRESSURE';
export const POSITION_IRAM = 'POSITION';
export const BOTH_IRAM = 'BOTH';

//URL's
export const STORAGE_LOCATION_URL = '/inventory-management/storage-locations';
export const VENDOR_URL = '/itemmanagement/vendors';
export const watchDogConstants = {
  WATCH_DOG_CHANNEL: 'watchDogChannel'
};

export const socketChannelName = {
  PLANNER_STATUS_CHANNEL: 'plannerStatusChannel',
  PRODUCTION_SCHEDULE_PAGE_REFRESH : 'planPageRefresh'
}

//Report file names
export const BATCH_SUMMARY_REPORT_NAME = 'Batch:  Batch Summary';

//Counter Constants
export const COUNTER_GROUP_TYPES = {
  ENGINEERING: 'ENGINEERING',
  REPORT: 'REPORT',
  MISCELLANEOUS: 'MISCELLANEOUS',
  ACTION_PARAMETER: 'ACTION_PARAMETER',
  MODE_PARAMETER: 'MODE_PARAMETER',
  MIXING_ACTION: 'MIXING_ACTION',
  MIXNIG_MODE: 'MIXNIG_MODE'
};

const SOFTWARE_PROVIDER = {
  COMPANY_NAME: 'Zeppelin Systems'
};

export const IRAM_CONTENT = `
Disclaimer:
iRam is intelligent ram controlling technology owned by HF Mixing Group. Use of this term does not imply any affiliation with or endorsement by them.
${SOFTWARE_PROVIDER.COMPANY_NAME} only provides recipe based interfacing with iRam controller from HF Mixing Group.
`;
